import { Grid, Icon, Typography } from "@mui/material";
import { memo } from "react";

function Automotive() {
  return (
    <Grid container spacing={1}>
      <Grid item xs={6} md={12}>
        <Icon className="wi-carwrap" fontSize="large" />
        <Typography
          variant="overline"
          display="block"
          px={1}
          noWrap
          gutterBottom
        >
          Car Wrap
        </Typography>
      </Grid>
      <Grid item xs={6} md={12}>
        <Icon className="wi-paint-protection" fontSize="large" />
        <Typography
          variant="overline"
          display="block"
          px={1}
          noWrap
          gutterBottom
        >
          Paint Protection
        </Typography>
      </Grid>
      <Grid item xs={6} md={12}>
        <Icon className="wi-striping" fontSize="large" />
        <Typography
          variant="overline"
          display="block"
          px={1}
          noWrap
          gutterBottom
        >
          Striping
        </Typography>
      </Grid>
      <Grid item xs={6} md={12}>
        <Icon className="wi-design-wrap" fontSize="large" />
        <Typography
          variant="overline"
          display="block"
          px={1}
          noWrap
          gutterBottom
        >
          Design Wrap
        </Typography>
      </Grid>
      <Grid item xs={6} md={12}>
        <Icon className="wi-accent-wrap" fontSize="large" />
        <Typography
          variant="overline"
          display="block"
          px={1}
          noWrap
          gutterBottom
        >
          Accent Wrap
        </Typography>
      </Grid>
      <Grid item xs={6} md={12}>
        <Icon className="wi-window-tint" fontSize="large" />
        <Typography
          variant="overline"
          display="block"
          px={1}
          noWrap
          gutterBottom
        >
          Window Tint
        </Typography>
      </Grid>
      <Grid item xs={6} md={12}>
        <Icon className="wi-windshield-protection" fontSize="large" />
        <Typography
          variant="overline"
          display="block"
          px={1}
          noWrap
          gutterBottom
        >
          Windshield Protection
        </Typography>
      </Grid>
      <Grid item xs={6} md={12}>
        <Icon className="wi-smoked-lights" fontSize="large" />
        <Typography
          variant="overline"
          display="block"
          px={1}
          noWrap
          gutterBottom
        >
          Smoked Lights
        </Typography>
      </Grid>
      <Grid item xs={6} md={12}>
        <Icon className="wi-wheels" fontSize="large" />
        <Typography
          variant="overline"
          display="block"
          px={1}
          noWrap
          gutterBottom
        >
          Wheels
        </Typography>
      </Grid>
      <Grid item xs={6} md={12}>
        <Icon className="wi-ceramic-coating" fontSize="large" />
        <Typography
          variant="overline"
          display="block"
          px={1}
          noWrap
          gutterBottom
        >
          Ceramic Coating
        </Typography>
      </Grid>
      <Grid item xs={6} md={12}>
        <Icon className="wi-chrome-delete" fontSize="large" />
        <Typography
          variant="overline"
          display="block"
          px={1}
          noWrap
          gutterBottom
        >
          Chrome Delete
        </Typography>
      </Grid>
    </Grid>
  );
}

export default memo(Automotive);
