import PersonIcon from "@mui/icons-material/Person";
import SendIcon from "@mui/icons-material/Send";
import StoreIcon from "@mui/icons-material/Store";
import { Alert, Box, Divider, Paper, Stack, Typography } from "@mui/material";
import moment from "moment";
import { memo, useState } from "react";
import ButtonControl from "../components/controls/ButtonControl";
import InputControl from "../components/controls/InputControl";
import useAppContext from "../hooks/useAppContext";

function DesignComments(props) {
  const { records, setRecords, selectedRecord } = props;
  const { apiPortal, auth } = useAppContext();

  const [values, setValues] = useState({
    designId: 0,
    isUser: false,
    comment: "",
  });
  const [isSaving, setIsSaving] = useState(false);
  const [fetchError, setFetchError] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSaving(true);
    values.designId = selectedRecord.designId;
    setValues({ ...values });

    // POST request using fetch
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: "Bearer " + auth.token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(values),
    };

    try {
      const response = await fetch(
        `${apiPortal}/DesignComments`,
        requestOptions
      );
      const isJson = response.headers
        .get("content-type")
        ?.includes("application/json");
      const json = isJson && (await response.json());

      // check for error response
      if (!response.ok) {
        // get error message from body or default to response status
        const error = (json && json.message) || response.status;
        throw Error(error);
      }

      selectedRecord.designComments = [...selectedRecord.designComments, json];
      // setSelectedRecord({ ...selectedRecord });
      const index = records.findIndex(
        (el) => el.designId === selectedRecord.designId
      );
      records[index] = selectedRecord;
      setRecords([...records]);
      setValues({ ...values, comment: "" });
    } catch (error) {
      setFetchError(
        <Alert severity="error">{!error.message ? error : error.message}</Alert>
      );
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <>
      {selectedRecord.designComments &&
        selectedRecord.designComments.map((el, i) => (
          <Box component="div" key={i}>
            <Stack
              alignItems="flex-start"
              direction={el.isUser ? "row" : "row-reverse"}
              sx={{ pb: 1 }}
            >
              <Paper sx={{ p: 2 }}>
                <Stack
                  direction="row"
                  spacing={1}
                  alignItems="center"
                  justifyContent="space-between"
                >
                  {el.isUser ? (
                    <>
                      <StoreIcon fontSize="small" />
                      <Typography variant="caption" color="text.secondary">
                        {moment(el.createdDate).local().format("lll")}
                      </Typography>
                    </>
                  ) : (
                    <>
                      <PersonIcon fontSize="small" />
                      <Typography variant="caption" color="text.secondary">
                        {moment(el.createdDate).local().format("lll")}
                      </Typography>
                    </>
                  )}
                </Stack>
                <Divider light sx={{ my: 0.5 }} />
                <Typography
                  sx={{ whiteSpace: "pre-line", textOverflow: "ellipsis" }}
                >
                  {el.comment}
                </Typography>
              </Paper>
            </Stack>
          </Box>
        ))}
      <form onSubmit={handleSubmit}>
        <Stack direction="row">
          <InputControl
            label="Type comments"
            name="comment"
            value={values.comment}
            onChange={(e) => setValues({ ...values, comment: e.target.value })}
            multiline
            required
            sx={{ m: 0 }}
          />
          <ButtonControl
            type="submit"
            variant="outlined"
            size="large"
            endIcon={<SendIcon />}
            isSaving={isSaving}
          />
        </Stack>
        {fetchError}
        <Typography variant="overline" sx={{ m: 1 }}>
          Send message to {selectedRecord.designComments[0]?.user} (shop)
        </Typography>
      </form>
    </>
  );
}

export default memo(DesignComments);
