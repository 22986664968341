//--- format Date
export function formatDate(data) {
  var monthStr = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  var date = new Date(data);
  return date.getDate() + " " + monthStr[date.getMonth()] + " " + date.getFullYear();
}

//format timestamp (hours:minutes AM/PM)
export function ts(date, bool) {
  var dt = new Date(date);
  var h = dt.getHours();
  var m = dt.getMinutes();
  var a = "AM";
  if (bool) {
    h = h < 10 ? "0" + h : h;
    m = m < 10 ? "0" + m : m;
    return h + "" + m;
  } else {
    if (h >= 12) {
      h = h - 12;
      a = "PM";
    }
    if (h === 0) {
      h = 12;
    }
    h = h < 10 ? "0" + h : h;
    m = m < 10 ? "0" + m : m;
    return h + ":" + m + " " + a;
  }
}

//format datestamp (Y-m-d)
export function ds(date) {
  var dt = new Date(date);
  var y = dt.getFullYear();
  var m = dt.getMonth() + 1;
  var d = dt.getDate();
  if (m < 10) m = "0" + m;

  if (d < 10) d = "0" + d;

  return y + "-" + m + "-" + d;
}

export function getRandomColor(name) {
  // get first alphabet in upper case
  const firstAlphabet = name.charAt(0).toLowerCase();
  // get the ASCII code of the character
  const asciiCode = firstAlphabet.charCodeAt(0);
  // number that contains 3 times ASCII value of character -- unique for every alphabet
  const colorNum = asciiCode.toString() + asciiCode.toString() + asciiCode.toString();

  var num = Math.round(0xffffff * parseInt(colorNum));
  var r = num & 255;
  var g = (num >> 8) & 255;
  var b = num & 255;

  return "rgb(" + r + ", " + g + ", " + b + ", 0.1)";
}

function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

export function stringAvatar(email) {
  return {
    sx: {
      bgcolor: stringToColor(email),
    },
    children: `${email.split("@")[0][0].toUpperCase()}`,
  };
}

export function currencyFormat(num, currency) {
  return (
    currency +
    " " +
    parseInt(num)
      .toFixed(2)
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
  );
}
