import ArticleIcon from "@mui/icons-material/Article";
import BlockIcon from "@mui/icons-material/Block";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import ContactPageIcon from "@mui/icons-material/ContactPage";
import DriveFileRenameOutlineSharpIcon from "@mui/icons-material/DriveFileRenameOutlineSharp";
import EditIcon from "@mui/icons-material/Edit";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import {
  Alert,
  AlertTitle,
  Avatar,
  Box,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Fab,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  Zoom,
} from "@mui/material";
import moment from "moment";
import { memo, useEffect, useRef, useState } from "react";
import SignatureCanvas from "react-signature-canvas";
import ButtonControl from "../components/controls/ButtonControl";
import { useForm } from "../components/useForm";
import useAppContext from "../hooks/useAppContext";
import { currencyFormat, getRandomColor } from "../scripts/Scripts";
import EditPersonalInfo from "./EditPersonalInfo";

const objRecord = {
  quoteId: 0,
  signature: "",
};

function ApproveQuote(props) {
  const { baseUrl, apiPortal, auth } = useAppContext();

  const [isEdit, setIsEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [fetchError, setFetchError] = useState(null);
  const [isDialog, setIsDialog] = useState(false);
  const [trimmedDataURL, setTrimmedDataURL] = useState(null);
  const [signError, setSignError] = useState("...");
  const [isAgree, setIsAgree] = useState(false);
  const [isAuthor, setIsAuthor] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [quote, setQuote] = useState();
  const sigCanvas = useRef({});

  const validate = (input = values) => {
    let temp = { ...errors };

    if ("quoteId" in input)
      temp.quoteId = input.quoteId ? "" : "Quote ID is required.";

    setErrors({ ...temp });
    if (input === values) return Object.values(temp).every((x) => x === "");
  };

  objRecord.quoteId = props.quoteParams.quoteId;
  const { values, setValues, errors, setErrors } = useForm(
    objRecord,
    true,
    validate
  );

  useEffect(() => {
    setQuote();
    setFetchError(null);
    const abortCtrl = new AbortController();

    // GET request using fetch with error handling
    const fetchRequest = async () => {
      const requestOptions = {
        headers: {
          Authorization: "Bearer " + auth.token,
          "Content-Type": "application/json",
        },
      };

      // Fetch request with error handling
      try {
        const response = await fetch(
          `${apiPortal}/Quotes/${props.quoteParams.quoteId}`,
          requestOptions,
          {
            signal: abortCtrl.signal,
          }
        );

        const isJson = response.headers
          .get("content-type")
          ?.includes("application/json");
        const json = isJson && (await response.json());

        // check for error response
        if (!response.ok) {
          // get error message from body or default to response statusText
          const error =
            (json && json.message) + ` : ${response.status}` || response.status;
          throw Error(error);
        }

        const quoteInfo = JSON.parse(json.quoteInfo);
        setQuote({ ...json, quoteInfo: quoteInfo });
      } catch (error) {
        setFetchError(
          <Alert severity="error">
            {!error.message ? error : error.message}
          </Alert>
        );
      } finally {
        setIsLoading(false);
      }
    };
    (async () => await fetchRequest())();

    return () => abortCtrl.abort();
    // eslint-disable-next-line
  }, [props.quoteParams.quoteId]);

  const handleSignature = () => {
    if (sigCanvas.current.isEmpty()) {
      setSignError("Signature is empty");
      return;
    }

    setIsDialog(false);
    setSignError("...");
    setTrimmedDataURL(
      sigCanvas.current.getTrimmedCanvas().toDataURL("image/png")
    );
    setValues({
      ...values,
      signature: sigCanvas.current.getTrimmedCanvas().toDataURL("image/png"),
    });
  };

  // Approve Quote
  const handleApprove = async (e) => {
    e.preventDefault();
    setIsSaving(true);
    const abortCtrl = new AbortController();

    // PUT Request using fetch
    const requestOptions = {
      method: "PUT",
      headers: {
        Authorization: "Bearer " + auth.token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(values),
    };

    try {
      const response = await fetch(
        `${apiPortal}/QuoteApprove/${props.quoteParams.quoteId}`,
        requestOptions,
        {
          signal: abortCtrl.signal,
        }
      );
      const isJson = response.headers
        .get("content-type")
        ?.includes("application/json");
      const data = isJson && (await response.json());

      // check for error response
      if (!response.ok) {
        // get error message from body or default to response status
        const error = (data && data.message) || response.status;
        throw Error(error);
      }

      setQuote({ ...quote, status: "Approved", jobCreatedDate: new Date() });
    } catch (error) {
      setFetchError(
        <Alert severity="error">{!error.message ? error : error.message}</Alert>
      );
    } finally {
      setIsSaving(false);
      abortCtrl.abort();
    }
  };

  // Re-open Quote
  const handleReopen = async () => {
    setIsSaving(true);
    const abortCtrl = new AbortController();

    // PUT Request using fetch
    const requestOptions = {
      method: "PUT",
      headers: {
        Authorization: "Bearer " + auth.token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(values),
    };

    try {
      const response = await fetch(
        `${apiPortal}/QuoteReOpen/${props.quoteParams.quoteId}`,
        requestOptions,
        {
          signal: abortCtrl.signal,
        }
      );
      const isJson = response.headers
        .get("content-type")
        ?.includes("application/json");
      const data = isJson && (await response.json());

      // check for error response
      if (!response.ok) {
        // get error message from body or default to response status
        const error = (data && data.message) || response.status;
        throw Error(error);
      }

      setQuote({ ...quote, status: "Request" });
    } catch (error) {
      setFetchError(
        <Alert severity="error">{!error.message ? error : error.message}</Alert>
      );
    } finally {
      setIsSaving(false);
      abortCtrl.abort();
    }
  };

  // const alertStatus = (status) => {
  //   switch (status) {
  //     case "Request":
  //       return "warning";

  //     case "Sent":
  //       return "info";

  //     case "Approved":
  //       return "success";

  //     case "Lost":
  //       return "error";

  //     default:
  //       return "info";
  //   }
  // };

  return (
    <>
      {fetchError ||
        (isLoading && (
          <CircularProgress size={20} color="inherit" sx={{ m: 2 }} />
        ))}
      {quote && quote.status === "Request" ? (
        <Alert severity="info">
          <AlertTitle>QUOTE ID: {quote.quoteId}</AlertTitle>
          In process . We will get back to you at {quote.customer.email} asap
        </Alert>
      ) : quote && quote.status === "Lost" ? (
        <Alert severity="error">
          <AlertTitle>QUOTE ID: {quote.quoteId}</AlertTitle>
          Sorry! the quote is expired
          <br />
          <ButtonControl
            text="Re-Open"
            variant="outlined"
            size="medium"
            startIcon={<RestartAltIcon />}
            isSaving={isSaving}
            onClick={handleReopen}
            sx={{ mt: 0.5 }}
          />
        </Alert>
      ) : (
        quote && (
          <Zoom in={true} style={{ transitionDelay: "100ms" }}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={5}>
                <Paper sx={{ pb: 3, height: "100%" }}>
                  <List>
                    <ListItem>
                      <ListItemAvatar>
                        <Avatar>
                          <ArticleIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText primary="Quote" secondary="Details" />
                    </ListItem>
                  </List>
                  <Divider />
                  <List sx={{ px: 3 }}>
                    <ListItem
                      secondaryAction={
                        <ListItemText primary={quote.industry} />
                      }
                    >
                      <ListItemText
                        primary={`ID: ${quote.quoteId}`}
                        secondary={moment(
                          quote.modifiedDate
                            ? quote.modifiedDate
                            : quote.createdDate
                        )
                          .local()
                          .format("DD MMM YYYY")}
                      />
                    </ListItem>
                    <Divider component="li" variant="middle" />
                    <ListItem>
                      <TableContainer>
                        <Table size="small">
                          <TableHead>
                            <TableRow>
                              <TableCell>Service</TableCell>
                              <TableCell align="right">Price</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {quote.quoteItems.map((it, i) =>
                              it.isGroup ? (
                                <TableRow
                                  key={i}
                                  sx={{ backgroundColor: "whitesmoke" }}
                                >
                                  <TableCell variant="head">
                                    {it.serviceName}
                                  </TableCell>
                                  <TableCell variant="head" align="right">
                                    {currencyFormat(it.price, quote.currency)}
                                  </TableCell>
                                </TableRow>
                              ) : (
                                !it.serviceValue?.startsWith("https:") && (
                                  <TableRow key={i}>
                                    <TableCell sx={{ px: 4 }}>
                                      <Typography
                                        sx={{ whiteSpace: "pre-line" }}
                                      >
                                        {it.serviceValue}
                                      </Typography>
                                      <Typography
                                        variant="body2"
                                        color="text.secondary"
                                      >
                                        {it.serviceName}
                                      </Typography>
                                    </TableCell>
                                    <TableCell></TableCell>
                                  </TableRow>
                                )
                              )
                            )}
                            <TableRow>
                              <TableCell
                                align="right"
                                colSpan={2}
                                variant="head"
                              >
                                Total:{" "}
                                {currencyFormat(
                                  quote.totalPrice,
                                  quote.currency
                                )}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </ListItem>
                    {quote.notes && (
                      <ListItem>
                        <ListItemText
                          primary={quote.notes}
                          secondary="Extra Info : (NOTES)"
                          sx={{ whiteSpace: "pre-line" }}
                        />
                      </ListItem>
                    )}
                    <ListItem>
                      <ListItemText
                        primary={quote.user && quote.user}
                        secondary="Shop Title"
                      />
                    </ListItem>
                    {Object.keys(quote.quoteInfo).length > 0 &&
                      quote.quoteInfo.constructor === Object && (
                        <>
                          <Divider component="li" variant="middle" />
                          {Object.keys(quote.quoteInfo).map((key) => (
                            <ListItem key={key}>
                              <ListItemText
                                sx={{
                                  whiteSpace: "pre-line",
                                }}
                                primary={quote.quoteInfo[key]}
                                secondary={key.trim()}
                              />
                            </ListItem>
                          ))}
                        </>
                      )}
                    <Divider component="li" variant="middle" />
                    <ListItem>
                      <Grid container spacing={1} alignItems="center">
                        {quote.quoteItems.map(
                          (it, i) =>
                            it.serviceValue?.startsWith("https:") && (
                              <Grid item key={i} xs={3}>
                                <Card sx={{ height: "100%" }}>
                                  <CardMedia
                                    component="img"
                                    height="100%"
                                    image={it.serviceValue}
                                    alt={it.serviceName}
                                    loading="lazy"
                                  />
                                  <CardContent sx={{ p: 1 }}>
                                    <Typography
                                      variant="caption"
                                      component="p"
                                      sx={{ textTransform: "capitalize" }}
                                      textAlign="center"
                                      noWrap
                                    >
                                      {it.serviceName}
                                    </Typography>
                                    <Divider light />
                                  </CardContent>
                                  <Divider light />
                                </Card>
                              </Grid>
                            )
                        )}

                        {quote.quoteImages.map((img, i) => (
                          <Grid item key={i} xs={3}>
                            <Card sx={{ height: "100%" }}>
                              <CardMedia
                                component="img"
                                height="72"
                                image={`${baseUrl}/Img/${img.imageName}`}
                                alt={img.imageName}
                                loading="lazy"
                              />
                              <CardContent
                                sx={{
                                  p: 1,
                                  backgroundColor: getRandomColor(
                                    img.imageCategory
                                  ),
                                }}
                              >
                                <Typography
                                  variant="caption"
                                  component="p"
                                  sx={{ textTransform: "capitalize" }}
                                  textAlign="center"
                                  noWrap
                                >
                                  {img.imageCategory}
                                </Typography>
                                <Divider light />
                              </CardContent>
                              <Divider light />
                            </Card>
                          </Grid>
                        ))}
                      </Grid>
                    </ListItem>
                  </List>
                </Paper>
              </Grid>
              <Grid item xs={12} md={4}>
                <Paper sx={{ height: "100%" }}>
                  <List>
                    <ListItem>
                      <ListItemAvatar>
                        <Avatar>
                          <ContactPageIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText primary="Contact" secondary="Details" />
                    </ListItem>
                  </List>
                  <Divider />
                  <List sx={{ px: 3 }}>
                    <ListItem divider>
                      <ListItemText
                        primary={quote.customer.email}
                        secondary="Email"
                      />
                    </ListItem>
                  </List>
                  {isEdit ? (
                    <List sx={{ px: 1 }}>
                      <ListItem>
                        <EditPersonalInfo
                          quote={quote}
                          setQuote={setQuote}
                          setIsEdit={setIsEdit}
                        />
                      </ListItem>
                    </List>
                  ) : (
                    <List sx={{ px: 3 }}>
                      <ListItem
                        secondaryAction={
                          <Tooltip title="Edit" placement="bottom" arrow>
                            <IconButton
                              color="primary"
                              aria-label="edit"
                              onClick={() => setIsEdit(true)}
                            >
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                        }
                      >
                        <ListItemText
                          primary={quote.customer.firstName}
                          secondary="First Name"
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemText
                          primary={quote.customer.lastName}
                          secondary="Last Name"
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemText
                          primary={quote.customer.phoneNumber}
                          secondary="Phone No."
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemText
                          primary={quote.customer.address}
                          secondary="Address"
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemText
                          primary={quote.customer.city}
                          secondary="City"
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemText
                          primary={quote.customer.state}
                          secondary="State"
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemText
                          primary={quote.customer.country}
                          secondary="Country"
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemText
                          primary={quote.customer.zipCode}
                          secondary="Zip Code"
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemText
                          primary={quote.customer.company}
                          secondary="Company"
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemText
                          primary={quote.customer.taxNumber}
                          secondary="Tax No."
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemText
                          primary={quote.customer.whereFindUs}
                          secondary="Where Did You Find Us"
                        />
                      </ListItem>
                    </List>
                  )}
                </Paper>
              </Grid>
              <Grid item xs={12} md={3} textAlign="center">
                {quote.jobCreatedDate && (
                  <>
                    <Alert severity="success">Approved</Alert>
                    <Divider />
                    <Card variant="outlined" sx={{ mt: 2 }}>
                      <CardMedia
                        component="img"
                        height="192"
                        image={quote.signature}
                        alt="signature"
                        sx={{ p: 1, objectFit: "contain" }}
                      />
                    </Card>
                  </>
                )}

                {!quote.jobCreatedDate && (
                  <form onSubmit={handleApprove}>
                    <input
                      type="hidden"
                      name="quoteId"
                      value={values.quoteId}
                      readOnly
                      hidden
                    />
                    <Card variant="outlined" sx={{ mt: 1.5, mb: 2 }}>
                      {trimmedDataURL && (
                        <>
                          <CardMedia
                            component="img"
                            height="192"
                            image={trimmedDataURL}
                            alt="signature"
                            sx={{ p: 1, objectFit: "contain" }}
                          />
                          <Divider />
                        </>
                      )}
                      <CardActions>
                        <ButtonControl
                          text="Signature"
                          variant="text"
                          color="primary"
                          startIcon={<DriveFileRenameOutlineSharpIcon />}
                          fullWidth
                          onClick={() => {
                            setIsDialog(true);
                            setSignError("...");
                          }}
                        >
                          Share
                        </ButtonControl>
                      </CardActions>
                    </Card>
                    <Box component="div" textAlign="left" px={1}>
                      <a
                        href="https://2wrap.com/sales-conditions/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Term &amp; Conditions
                      </a>
                      <FormControl fullWidth>
                        <FormControlLabel
                          label="I agree to terms and conditions"
                          control={<Checkbox />}
                          onChange={(e) => setIsAgree(e.target.checked)}
                        />
                        <FormControlLabel
                          label="Authority to Sign"
                          control={<Checkbox />}
                          onChange={(e) => setIsAuthor(e.target.checked)}
                        />
                      </FormControl>
                      <Typography variant="body2" color="text.secondary">
                        The person signing below on behalf of any party hereby
                        represents and warrants that s/he or it is signing with
                        full and complete authority to bind the party on whose
                        behalf of whom s/he or it is signing, to each and every
                        terms of this Agreement.
                      </Typography>
                    </Box>
                    <Divider sx={{ my: 1 }}>
                      {fetchError ? (
                        fetchError
                      ) : (
                        <Avatar>
                          <TaskAltIcon />
                        </Avatar>
                      )}
                    </Divider>
                    <Typography
                      variant="body2"
                      color={trimmedDataURL ? "success.main" : "error"}
                      gutterBottom
                    >
                      Please provide signature.
                      {trimmedDataURL ? (
                        <CheckIcon
                          fontSize="inherit"
                          sx={{ ml: 0.5, verticalAlign: "text-top" }}
                        />
                      ) : (
                        <BlockIcon
                          fontSize="inherit"
                          sx={{ ml: 0.5, verticalAlign: "text-top" }}
                        />
                      )}
                    </Typography>
                    <Typography
                      variant="body2"
                      color={isAgree ? "success.main" : "error"}
                      gutterBottom
                    >
                      Please check agree to terms.
                      {isAgree ? (
                        <CheckIcon
                          fontSize="inherit"
                          sx={{ ml: 0.5, verticalAlign: "text-top" }}
                        />
                      ) : (
                        <BlockIcon
                          fontSize="inherit"
                          sx={{ ml: 0.5, verticalAlign: "text-top" }}
                        />
                      )}
                    </Typography>
                    <Typography
                      variant="body2"
                      color={isAuthor ? "success.main" : "error"}
                      gutterBottom
                    >
                      Please check authority to sign.
                      {isAuthor ? (
                        <CheckIcon
                          fontSize="inherit"
                          sx={{ ml: 0.5, verticalAlign: "text-top" }}
                        />
                      ) : (
                        <BlockIcon
                          fontSize="inherit"
                          sx={{ ml: 0.5, verticalAlign: "text-top" }}
                        />
                      )}
                    </Typography>
                    {trimmedDataURL && isAgree && isAuthor ? (
                      <ButtonControl
                        type="submit"
                        text="Approve"
                        endIcon={<TaskAltIcon />}
                        color="success"
                        isSaving={isSaving}
                        sx={{ mt: 1 }}
                      />
                    ) : (
                      <ButtonControl
                        text="Approve"
                        endIcon={<BlockIcon />}
                        disabled
                        sx={{ mt: 1 }}
                      />
                    )}
                  </form>
                )}
              </Grid>
            </Grid>
          </Zoom>
        )
      )}

      {/* Signature canvas popupDialog */}
      {quote && !quote.jobCreatedDate && (
        <Dialog open={isDialog} fullScreen>
          <DialogTitle>
            <Stack
              direction="row"
              divider={<Divider orientation="vertical" flexItem />}
              spacing={2}
              alignItems="center"
              justifyContent="center"
            >
              <DriveFileRenameOutlineSharpIcon />
              <Typography variant="subtitle1">
                Quote ID: {quote.quoteId}
              </Typography>
              <ButtonControl
                text="Cancel"
                variant="outlined"
                color="error"
                endIcon={<CancelIcon />}
                onClick={() => setIsDialog(false)}
              />
            </Stack>
          </DialogTitle>
          <DialogContent>
            <Box
              sx={{
                margin: "0 auto",
                height: "100%",
                border: "1px dashed grey",
              }}
              maxWidth="md"
            >
              <SignatureCanvas
                penColor="blue"
                canvasProps={{ className: "signCanvas" }}
                ref={sigCanvas}
              />
            </Box>
          </DialogContent>
          <Typography component="p" color="error" textAlign="center">
            {signError}
          </Typography>
          <Stack
            direction="row"
            divider={<Divider orientation="vertical" flexItem />}
            spacing={2}
            alignItems="center"
            justifyContent="center"
            sx={{ p: 3 }}
          >
            <Tooltip title="Clear">
              <Fab onClick={() => sigCanvas.current.clear()}>
                <ClearIcon />
              </Fab>
            </Tooltip>
            <Tooltip title="Save">
              <Fab color="primary" onClick={handleSignature}>
                <CheckIcon />
              </Fab>
            </Tooltip>
          </Stack>
        </Dialog>
      )}
    </>
  );
}

export default memo(ApproveQuote);
