import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { memo } from "react";

function RadioGroupControl(props) {
  const { label, name, value, onChange, options, ...other } = props;
  return (
    <FormControl margin="dense" fullWidth>
      <FormLabel>{label}</FormLabel>
      <RadioGroup
        key={value}
        aria-label={name}
        name={name}
        defaultValue={value}
        onChange={onChange}
        {...other}
      >
        {options.map((option, i) => (
          <FormControlLabel
            key={option.value}
            value={option.value}
            control={<Radio />}
            label={option.label}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
}

export default memo(RadioGroupControl);
