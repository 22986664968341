import { createContext, useState } from "react";

export const AppContext = createContext({});

export function AppProvider({ children }) {
  const baseUrl = "https://wrapapi.azurewebsites.net";
  const api = "https://wrapapi.azurewebsites.net/api";
  // const baseUrl = "https://localhost:44389";
  // const api = "https://localhost:44389/api";
  const apiAuths = `${api}/Auths`;
  const apiUsers = `${api}/AppUsers`;
  const apiRequest = `${api}/Request`;
  const apiPortal = `${api}/Portal`;
  const apiColors = `${api}/Request/Colors`;
  const [response, setResponse] = useState();
  const [popupDialog, setPopupDialog] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState({});

  const store = JSON.parse(localStorage.getItem("2wrap-Token"));
  const [auth, setAuth] = useState(store ? store : false);

  return (
    <AppContext.Provider
      value={{
        baseUrl,
        api,
        apiAuths,
        apiUsers,
        apiRequest,
        apiPortal,
        apiColors,
        response,
        setResponse,
        popupDialog,
        setPopupDialog,
        confirmDialog,
        setConfirmDialog,
        auth,
        setAuth,
      }}
    >
      {children}
    </AppContext.Provider>
  );
}
