import TextField from "@mui/material/TextField";
import { memo } from "react";

function InputControl(props) {
  const {
    variant,
    label,
    name,
    value,
    onChange,
    helperText,
    error = null,
    ...others
  } = props;
  return (
    <TextField
      variant={variant || "outlined"}
      label={label}
      name={name}
      value={value}
      onChange={onChange}
      onBlur={onChange}
      helperText={helperText || ""}
      {...(error && { error: true, helperText: error })}
      {...others}
      margin="dense"
      fullWidth
    />
  );
}

export default memo(InputControl);
