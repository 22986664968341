import ArchitectureIcon from "@mui/icons-material/Architecture";
import AssignmentIcon from "@mui/icons-material/Assignment";
import ReadMoreIcon from "@mui/icons-material/ReadMore";
import WestIcon from "@mui/icons-material/West";
import {
  Alert,
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  CircularProgress,
  Container,
  Grid,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import moment from "moment/moment";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import PageHeader from "../components/PageHeader";
import TabPanel from "../components/TabPanel";
import useAppContext from "../hooks/useAppContext";
import ApproveQuote from "./ApproveQuote";
import Designs from "./Designs";

const alertStatus = (status) => {
  switch (status) {
    case "Request":
    case "Todo":
      return "warning";

    case "Sent":
    case "Waiting":
      return "info";

    case "Approved":
      return "success";

    case "Lost":
      return "error";

    default:
      return "info";
  }
};

export default function CustomerPortal() {
  const { apiPortal, auth } = useAppContext();

  const [isLoading, setIsLoading] = useState(false);
  const [fetchError, setFetchError] = useState(null);
  const [quoteParams, setQuoteParams] = useState();
  const [records, setRecords] = useState(null);
  const [tabValue, setTabValue] = useState(0);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/portal";

  useEffect(() => {
    navigate(auth ? from : "/login", { replace: true });
    return () => {};
    // eslint-disable-next-line
  }, [auth, navigate, from]);

  useEffect(() => {
    const abortCtrl = new AbortController();

    const requestOptions = {
      headers: {
        Authorization: "Bearer " + auth.token,
        "Content-Type": "application/json",
      },
    };

    // GET request using fetch with error handling
    const fetchRequest = async () => {
      try {
        const response = await fetch(apiPortal, requestOptions, {
          signal: abortCtrl.signal,
        });
        const isJson = response.headers
          .get("content-type")
          ?.includes("application/json");
        const json = isJson && (await response.json());

        // check for error response
        if (!response.ok) {
          // get error message from body or default to response statusText
          const error =
            (json && json.message) + ` : ${response.status}` || response.status;
          throw Error(error);
        }

        setRecords(json);
        setFetchError(null);
      } catch (error) {
        setFetchError(
          <Alert severity="error">
            {!error.message ? error : error.message}
          </Alert>
        );
      } finally {
        setIsLoading(false);
      }
    };
    (async () => await fetchRequest())();

    return () => {
      abortCtrl.abort();
    };
    // eslint-disable-next-line
  }, []);

  //Request Quote
  const openDetails = (obj) => {
    setFetchError(null);
    setQuoteParams(obj);
    setTabValue(0);
  };

  return (
    <>
      <PageHeader
        title="Customer Portal"
        subTitle="Service overview and details"
        icon="wi-icon-2wrap"
        maxWidth="xl"
        logBtn={true}
      />
      <Container maxWidth="xl" sx={{ pb: 5 }}>
        {fetchError ||
          (isLoading && (
            <CircularProgress size={20} color="inherit" sx={{ m: 2 }} />
          ))}
        {!quoteParams?.quoteId && records && (
          <>
            <Grid container spacing={2}>
              {records.map((row) => (
                <Grid item key={row.quoteId} xs>
                  <Card>
                    <CardActionArea
                      onClick={() =>
                        openDetails({
                          quoteId: row.quoteId,
                          jobCreatedDate: row.jobCreatedDate,
                        })
                      }
                    >
                      <CardHeader
                        action={<ReadMoreIcon />}
                        title={`ID: ${row.quoteId}`}
                        subheader={moment(
                          row.modifiedDate ? row.modifiedDate : row.createdDate
                        )
                          .local()
                          .format("ll")}
                      />
                      <CardContent>
                        <Typography variant="subtitle1">
                          {row.title ? row.title : "..."}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          {row.industry}
                        </Typography>
                        <Alert severity={alertStatus(row.status)}>
                          {row.status}
                        </Alert>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </>
        )}
        {quoteParams?.quoteId && (
          <>
            <Tabs value={tabValue} onChange={handleChange} aria-label="tabs">
              <Tab icon={<AssignmentIcon />} label="Quote" />
              <Tab icon={<ArchitectureIcon />} label="Design" />
              <Tab
                icon={<WestIcon />}
                label="GO BACK"
                onClick={() => setQuoteParams()}
                sx={{ color: "secondary.main" }}
              />
            </Tabs>
            <TabPanel value={tabValue} index={0}>
              <ApproveQuote quoteParams={quoteParams} />
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
              <Designs quoteParams={quoteParams} />
            </TabPanel>
          </>
        )}
      </Container>
    </>
  );
}
