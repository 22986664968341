import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import {
  Alert,
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  CardMedia,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  Link,
  Zoom,
} from "@mui/material";
import moment from "moment";
import { memo, useEffect, useState } from "react";
import ConfirmDialog from "../components/ConfirmDialog";
import ButtonControl from "../components/controls/ButtonControl";
import PopupDialog from "../components/PopupDialog";
import useAppContext from "../hooks/useAppContext";
import DesignComments from "./DesignComments";

function Designs(props) {
  const { apiPortal, auth, setConfirmDialog } = useAppContext();

  const [records, setRecords] = useState();
  const [selectedRecord, setSelectedRecord] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [fetchError, setFetchError] = useState(null);
  const [popupDialog, setPopupDialog] = useState(false);
  const [popupContent, setPopupContent] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [isAny, setIsAny] = useState(false);

  useEffect(() => {
    const abortCtrl = new AbortController();

    const requestOptions = {
      headers: {
        Authorization: "Bearer " + auth.token,
        "Content-Type": "application/json",
      },
    };

    // GET request using fetch with error handling
    const fetchRequest = async () => {
      try {
        const response = await fetch(
          `${apiPortal}/Designs/${props.quoteParams.quoteId}`,
          requestOptions,
          {
            signal: abortCtrl.signal,
          }
        );
        const isJson = response.headers
          .get("content-type")
          ?.includes("application/json");
        const json = isJson && (await response.json());

        // check for error response
        if (!response.ok) {
          // get error message from body or default to response statusText
          const error =
            (json && json.message) + ` : ${response.status}` || response.status;
          throw Error(error);
        }

        setRecords(json);
        //Check if any is approved
        setIsAny(json.some(({ isApproved }) => isApproved === true));
        setSelectedRecord({ ...json[0] });
        setFetchError(null);
      } catch (error) {
        setFetchError(
          <Alert severity="error">
            {!error.message ? error : error.message}
          </Alert>
        );
      } finally {
        setIsLoading(false);
      }
    };
    (async () => await fetchRequest())();

    return () => {
      abortCtrl.abort();
    };
    // eslint-disable-next-line
  }, [apiPortal, props.quoteParams.quoteId]);

  const handleApprove = async (designId) => {
    setConfirmDialog({ isOpen: false });
    setIsSaving(true);

    // PUT Request using fetch
    const requestOptions = {
      method: "PUT",
      headers: {
        Authorization: "Bearer " + auth.token,
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await fetch(
        `${apiPortal}/DesignApprove/${designId}`,
        requestOptions
      );
      const isJson = response.headers
        .get("content-type")
        ?.includes("application/json");
      const json = isJson && (await response.json());

      // check for error response
      if (!response.ok) {
        // get error message from body or default to response statusText
        const error =
          (json && json.message) + ` : ${response.status}` || response.status;
        throw Error(error);
      }

      const index = records.findIndex((el) => el.designId === designId);
      records[index] = json;
      setSelectedRecord({ ...json });
      setFetchError(null);
      setIsAny(records.some(({ isApproved }) => isApproved === true));
    } catch (error) {
      setFetchError(
        <Alert severity="error">{!error.message ? error : error.message}</Alert>
      );
    } finally {
      setIsSaving(false);
    }
  };

  const handleSelect = (designId) => {
    const index = records.findIndex((el) => el.designId === designId);
    setSelectedRecord({ ...records[index] });
  };

  return (
    <>
      {fetchError ||
        (isLoading && (
          <CircularProgress size={20} color="inherit" sx={{ m: 3 }} />
        ))}
      {records?.length > 0 ? (
        <Zoom in={true} style={{ transitionDelay: "100ms" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={2}>
              <Grid container spacing={2}>
                {records.map((el, i) => (
                  <Grid key={i} item xs={4} md={12}>
                    <Card
                      variant={
                        el.designId === selectedRecord.designId
                          ? "elevation"
                          : "outlined"
                      }
                      sx={{
                        height: { xs: "100%", md: "auto" },
                        bgcolor:
                          el.designId === selectedRecord.designId
                            ? "paper"
                            : "divider",
                        filter:
                          el.designId === selectedRecord.designId
                            ? "saturate(100%)"
                            : "saturate(0%)",
                      }}
                    >
                      <CardHeader
                        action={
                          <Box sx={{ textAlign: "right" }}>
                            {el.isApproved && (
                              <Chip
                                label="Approved"
                                color="success"
                                icon={<TaskAltIcon />}
                                sx={{ mb: 0.5, borderRadius: 1 }}
                              />
                            )}
                          </Box>
                        }
                        title={`DESIGN - ${el.designId}`}
                        titleTypographyProps={{
                          fontSize: "inherit",
                        }}
                        subheader={moment(el.createdDate).local().format("ll")}
                        subheaderTypographyProps={{
                          fontSize: "small",
                        }}
                      />
                      <Divider />
                      {el.mime === "application/pdf" ? (
                        <CardContent align="center" sx={{ p: 0 }}>
                          <embed
                            src={el.fileName}
                            type="application/pdf"
                            width="100%"
                            height={256}
                          />
                        </CardContent>
                      ) : (
                        <CardActionArea
                          onClick={() => handleSelect(el.designId)}
                        >
                          <CardMedia
                            component="img"
                            height="100%"
                            image={el.fileName}
                            alt={el.fileName}
                            loading="lazy"
                          />
                        </CardActionArea>
                      )}
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card>
                <CardHeader
                  action={
                    <Box sx={{ textAlign: "right" }}>
                      {selectedRecord.isApproved && (
                        <>
                          <Chip
                            label="Approved"
                            color="success"
                            icon={<TaskAltIcon />}
                            sx={{ mb: 0.5, borderRadius: 1 }}
                          />
                          <br />
                        </>
                      )}
                      {!isAny && (
                        <>
                          <ButtonControl
                            text="Approve"
                            variant="outlined"
                            color="success"
                            size="medium"
                            isSaving={isSaving}
                            endIcon={<TaskAltIcon />}
                            sx={{ mb: 0.5 }}
                            onClick={() =>
                              setConfirmDialog(
                                props.quoteParams.jobCreatedDate
                                  ? {
                                      isOpen: true,
                                      title: `Are you sure? approve design : ${selectedRecord.designId}`,
                                      subTitle: "You can't undo this operation",
                                      onConfirm: () => {
                                        handleApprove(selectedRecord.designId);
                                      },
                                    }
                                  : {
                                      isOpen: true,
                                      title: `First you need to approve quote : ${selectedRecord.quoteId}`,
                                      subTitle: "Action required",
                                      onConfirm: () => {
                                        setConfirmDialog({ isOpen: false });
                                      },
                                    }
                              )
                            }
                          />
                          <br />
                        </>
                      )}
                      <ButtonControl
                        component={Link}
                        href={selectedRecord.fileName}
                        text={selectedRecord.mime}
                        variant="text"
                        size="medium"
                        startIcon={<DownloadRoundedIcon />}
                        download={selectedRecord.designId}
                        sx={{ textTransform: "lowercase" }}
                      />
                    </Box>
                  }
                  title={`DESIGN - ${selectedRecord.designId}`}
                  subheader={moment(selectedRecord.createdDate)
                    .local()
                    .format("ll")}
                />
                <Divider />
                {selectedRecord.mime === "application/pdf" ? (
                  <CardContent align="center" sx={{ p: 0 }}>
                    <embed
                      src={selectedRecord.fileName}
                      type="application/pdf"
                      width="100%"
                      height="550px"
                    />
                  </CardContent>
                ) : (
                  <CardActionArea
                    onClick={() => {
                      setPopupContent(selectedRecord.fileName);
                      setPopupDialog(true);
                    }}
                  >
                    <CardMedia
                      component="img"
                      height="100%"
                      image={selectedRecord.fileName}
                      alt={selectedRecord.fileName}
                      loading="lazy"
                    />
                  </CardActionArea>
                )}
              </Card>
            </Grid>
            <Grid item xs={12} md={4}>
              <DesignComments
                records={records}
                setRecords={setRecords}
                selectedRecord={selectedRecord}
              />
            </Grid>
          </Grid>
        </Zoom>
      ) : (
        records && <Alert severity="info">No Design Yet!</Alert>
      )}
      <PopupDialog
        popupDialog={popupDialog}
        setPopupDialog={setPopupDialog}
        maxWidth={false}
      >
        <img src={popupContent} alt={popupContent} />
      </PopupDialog>
      <ConfirmDialog />
    </>
  );
}

export default memo(Designs);
