import { Grid, Icon, Typography } from "@mui/material";
import { memo } from "react";

function Architectural() {
  return (
    <Grid container spacing={1}>
      <Grid item xs={6} md={12}>
        <Icon className="wi-building-wrap" fontSize="large" />
        <Typography
          variant="overline"
          display="block"
          px={1}
          noWrap
          gutterBottom
        >
          Building Wrap
        </Typography>
      </Grid>
      <Grid item xs={6} md={12}>
        <Icon className="wi-canvas-frames" fontSize="large" />
        <Typography
          variant="overline"
          display="block"
          px={1}
          noWrap
          gutterBottom
        >
          Canvas &amp; Frames
        </Typography>
      </Grid>
      <Grid item xs={6} md={12}>
        <Icon className="wi-furniture" fontSize="large" />
        <Typography
          variant="overline"
          display="block"
          px={1}
          noWrap
          gutterBottom
        >
          Furniture
        </Typography>
      </Grid>
      <Grid item xs={6} md={12}>
        <Icon className="wi-wall-wrap" fontSize="large" />
        <Typography
          variant="overline"
          display="block"
          px={1}
          noWrap
          gutterBottom
        >
          Wall Wrap
        </Typography>
      </Grid>
      <Grid item xs={6} md={12}>
        <Icon className="wi-window-film" fontSize="large" />
        <Typography
          variant="overline"
          display="block"
          px={1}
          noWrap
          gutterBottom
        >
          Window Film
        </Typography>
      </Grid>
    </Grid>
  );
}

export default memo(Architectural);
