import { Checkbox, FormControl, FormControlLabel } from "@mui/material";
import { memo } from "react";

function CheckboxControl(props) {
  const { label, name, value, onChange } = props;

  const convertToDefEventPara = (name, value) => ({
    target: { name, value },
  });

  return (
    <FormControl margin="dense" fullWidth>
      <FormControlLabel
        control={
          <Checkbox
            name={name}
            checked={value}
            onChange={(e) =>
              onChange(convertToDefEventPara(name, e.target.checked))
            }
          />
        }
        label={label}
      />
    </FormControl>
  );
}

export default memo(CheckboxControl);
