import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import { Chip, Container } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./2wrap-fonts.css";
import { AppProvider } from "./context/AppContext";
import CustomerLogin from "./pages/CustomerLogin";
import CustomerPortal from "./pages/CustomerPortal";
import ThankYou from "./pages/ThankYou";
import AdvertisingForm from "./pages/quotes/AdvertisingForm";
import AeronauticsForm from "./pages/quotes/AeronauticsForm";
import ArchitecturalForm from "./pages/quotes/ArchitecturalForm";
import AutomotiveForm from "./pages/quotes/AutomotiveForm";
import Industries from "./pages/quotes/Industries";
import NauticalForm from "./pages/quotes/NauticalForm";
import SpecialtiesForm from "./pages/quotes/SpecialtiesForm";

const theme = createTheme({
  palette: {
    primary: {
      main: "#121212",
    },
    secondary: {
      main: "#9e0c00",
    },
    background: {
      default: "#fcfcfc",
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppProvider>
        <BrowserRouter>
          <Routes>
            <Route
              path="/"
              render={({ location }) => {
                window.gtag("config", "AW-10786748985", {
                  page_path: location.pathname + location.search,
                });
              }}
            >
              <Route index element={<Industries />} />
              <Route path="automotive-services" element={<AutomotiveForm />} />
              <Route
                path="advertising-services"
                element={<AdvertisingForm />}
              />
              <Route
                path="architectural-services"
                element={<ArchitecturalForm />}
              />
              <Route path="nautical-services" element={<NauticalForm />} />
              <Route
                path="aeronautical-services"
                element={<AeronauticsForm />}
              />
              <Route
                path="specialties-services"
                element={<SpecialtiesForm />}
              />
              <Route path="login" element={<CustomerLogin />} />
              <Route path="portal" element={<CustomerPortal />} />
              <Route path="approve-quote" element={<CustomerPortal />} />
              <Route path="thank-you" element={<ThankYou />} />
            </Route>
          </Routes>
        </BrowserRouter>
        <Container sx={{ my: 3 }}>
          <Chip
            label="Powered by wrap-up"
            component="a"
            href="https://www.wrap-up.app"
            target="_blank"
            variant="outlined"
            clickable
          />
        </Container>
      </AppProvider>
    </ThemeProvider>
  );
}

export default App;
