import ErrorIcon from "@mui/icons-material/Error";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import { memo } from "react";
import ButtonControl from "../components/controls/ButtonControl";
import useAppContext from "../hooks/useAppContext";

function ConfirmDialog() {
  const { confirmDialog, setConfirmDialog } = useAppContext();

  function NewlineText(props) {
    const text = props.text;
    const newText = text.split("\n").map((str, i) => (
      <Typography
        key={i}
        sx={{ fontSize: "1rem", color: "primary.dark" }}
        gutterBottom
      >
        {str}
      </Typography>
    ));

    return newText;
  }

  return (
    <Dialog open={confirmDialog.isOpen || false} fullWidth maxWidth="sm">
      <DialogTitle
        sx={{ p: 7, textAlign: "center", textTransform: "capitalize" }}
      >
        {confirmDialog?.title && <NewlineText text={confirmDialog.title} />}
      </DialogTitle>

      <DialogContent dividers sx={{ px: 3, backgroundColor: "primary.light" }}>
        <Stack
          direction="row"
          alignItems="center"
          spacing={2}
          justifyContent="flex-end"
        >
          <Typography sx={{ color: "white" }}>
            {confirmDialog.subTitle}
          </Typography>
          <ErrorIcon sx={{ color: "white" }} />
        </Stack>
      </DialogContent>

      <DialogActions sx={{ p: 3, backgroundColor: "divider" }}>
        <ButtonControl
          text="Cancel"
          onClick={() => setConfirmDialog({ ...confirmDialog, isOpen: false })}
        />
        <ButtonControl
          color="success"
          text="Ok"
          onClick={confirmDialog.onConfirm}
        />
      </DialogActions>
    </Dialog>
  );
}

export default memo(ConfirmDialog);
