import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LogoutIcon from "@mui/icons-material/Logout";
import {
  Card,
  CardActionArea,
  CardMedia,
  Chip,
  Container,
  Grid,
  Icon,
  Link,
  Paper,
  Typography,
} from "@mui/material";
import { memo } from "react";
import useAppContext from "../hooks/useAppContext";
import ButtonControl from "./controls/ButtonControl";

function PageHeader(props) {
  const { title, subTitle, icon, maxWidth, align, logBtn = false } = props;
  const { auth, setAuth } = useAppContext();

  const handleLogout = () => {
    localStorage.removeItem("2wrap-Token");
    setAuth(null);
  };

  return (
    <>
      <Paper square sx={{ px: 6, py: 1.6, mb: 5, bgcolor: "black" }}>
        <Card
          sx={{ maxWidth: 200, visibility: { xs: "hidden", md: "inherit" } }}
        >
          <CardActionArea component={Link} href="https://2wrap.com/">
            <CardMedia
              component="img"
              image="/img/2wrap-name-logo.jpg"
              alt="2wrap.com"
              loading="lazy"
            />
          </CardActionArea>
        </Card>
        <Paper
          component={Link}
          href="https://2wrap.com/"
          sx={{
            p: 5,
            pb: 3.5,
            borderRadius: "100%",
            position: "absolute",
            left: "50%",
            top: "0",
            transform: "translate(-50%, -32px)",
            bgcolor: "black",
            color: "white",
          }}
        >
          <Icon className={icon} sx={{ fontSize: 68 }} />
        </Paper>
      </Paper>
      <Container
        maxWidth={maxWidth || "lg"}
        sx={{ p: 2 }}
        align={align || "left"}
      >
        <Grid container spacing={2}>
          <Grid item xs>
            <Typography
              variant="h5"
              component="h2"
              noWrap
              sx={{ textTransform: "uppercase" }}
            >
              {title}
            </Typography>
            <Typography>{subTitle}</Typography>
          </Grid>
          {logBtn && auth && (
            <Grid item xs="auto">
              <Chip
                icon={<AccountCircleIcon />}
                label={auth.email}
                sx={{ mr: 0.5, borderRadius: 1 }}
              />
              <ButtonControl
                text="Logout"
                variant="outlined"
                size="small"
                endIcon={<LogoutIcon />}
                onClick={handleLogout}
              />
            </Grid>
          )}
        </Grid>
      </Container>
    </>
  );
}

export default memo(PageHeader);
