import {
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  Divider,
  Icon,
  Slide,
  Switch,
  Typography,
} from "@mui/material";
import { memo, useState } from "react";

function ServiceCard(props) {
  const { services, values, setValues, switchName, title, icon, paragraph } =
    props;

  const [checked, setChecked] = useState(false);

  const toggleSwitch = (e) => {
    let current = e.currentTarget;
    current.getElementsByTagName("input")[0].click();
  };

  const handleSwitch = (e) => {
    e.stopPropagation();
    const { checked } = e.target;
    setChecked(checked);
    if (checked === false) {
      services.forEach((item) => {
        values[item] = typeof values[item] === "string" ? "" : false;
      });
      setValues({ ...values, [switchName]: checked });
    }
  };

  return (
    <Card
      elevation={3}
      align="left"
      sx={{ position: "relative", height: checked === true ? "100%" : "auto" }}
    >
      <CardActionArea
        onClick={toggleSwitch}
        sx={{
          "&:hover > div.MuiCardContent-root": {
            p: 2,
            maxHeight: "300px",
            transition: "all 0.3s ease-in-out 200ms",
          },
        }}
      >
        <CardHeader
          action={
            <Switch
              color="secondary"
              name={switchName}
              onClick={handleSwitch}
              checked={checked}
            />
          }
          avatar={<Icon className={icon} sx={{ fontSize: 44 }} />}
          title={title}
          titleTypographyProps={{ variant: "subtitle1" }}
        />
        <Divider />
        {!checked && (
          <CardContent
            sx={{
              p: 0,
              maxHeight: 0,
              bgcolor: "whitesmoke",
              overflow: "hidden",
              textOverflow: "ellipsis",
              transition: "all 0.3s ease-in-out 0ms",
            }}
          >
            <Typography component="p" variant="body2" color="text.secondary">
              {paragraph}
            </Typography>
          </CardContent>
        )}
      </CardActionArea>
      {checked && (
        <Slide in={checked}>
          <CardContent>{props.children}</CardContent>
        </Slide>
      )}
    </Card>
  );
}

export default memo(ServiceCard);
