import {
  Alert,
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  Fab,
  FormControl,
  Grid,
  Icon,
  ImageList,
  ImageListItem,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Slide,
  Typography,
} from "@mui/material";
import { useState } from "react";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CheckIcon from "@mui/icons-material/Check";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import PaletteIcon from "@mui/icons-material/Palette";
import { Link, Navigate, useLocation } from "react-router-dom";
import ColorsList from "../../components/ColorsList";
import PageHeader from "../../components/PageHeader";
import PopupDialog from "../../components/PopupDialog";
import ServiceCard from "../../components/ServiceCard";
import ButtonControl from "../../components/controls/ButtonControl";
import CheckboxControl from "../../components/controls/CheckboxControl";
import InputControl from "../../components/controls/InputControl";
import RadioGroupControl from "../../components/controls/RadioGroupControl";
import SelectControl from "../../components/controls/SelectControl";
import useAppContext from "../../hooks/useAppContext";
import PersonalInfoForm from "./PersonalInfoForm";

const objRecord = {
  industry: "Automotive",
  vehicleBrand_: "",
  vehicleType_: "",
  vehicleAge_: "New",
  vehicleColor_: "",
  necessaryInfo_: "",
  email: "",
  firstName: "",
  lastName: "",
  address: "",
  city: "",
  state: "",
  country: "",
  zipCode: "",
  phoneNumber: "",
  company: "",
  taxNumber: "",
  whereFindUs: "",
};

export default function AutomotiveForm() {
  const { apiRequest, response, setResponse, popupDialog, setPopupDialog } =
    useAppContext();

  const [values, setValues] = useState({ ...objRecord });
  const [errors, setErrors] = useState({});
  const [isSaving, setIsSaving] = useState(false);
  const [fetchError, setFetchError] = useState(false);
  const [imgList, setImgList] = useState([]);
  const [isEmailConflict, setEmailConflict] = useState(false);
  const [isNotFound, setNotFound] = useState(false);
  const [colorAttr, setColorAttr] = useState();
  const location = useLocation();

  const validate = (input = values) => {
    let temp = { ...errors };

    // Customer Info validation
    if ("email" in input) {
      temp.email = /^[A-Z0-9._%+-]+@([A-Z0-9-]+\.)+[A-Z]{2,4}$/i.test(
        input.email
      )
        ? ""
        : "Email in not valid.";
      temp.email = input.email ? temp.email : "Email is required.";
    }
    if ("firstName" in input)
      temp.firstName = input.firstName ? "" : "This field is required.";
    if ("lastName" in input)
      temp.lastName = input.lastName ? "" : "This field is required.";
    if ("address" in input)
      temp.address = input.address ? "" : "This field is required.";
    if ("city" in input)
      temp.city = input.city ? "" : "This field is required.";
    if ("state" in input)
      temp.state = input.state ? "" : "This field is required.";
    if ("country" in input)
      temp.country = input.country ? "" : "This field is required.";
    if ("zipCode" in input)
      temp.zipCode = input.zipCode ? "" : "This field is required.";
    if ("phoneNumber" in input)
      temp.phoneNumber = input.phoneNumber ? "" : "This field is required.";

    // Vehicle Info Validation
    if ("vehicleBrand_" in input)
      temp.vehicleBrand_ = input.vehicleBrand_ ? "" : "This field is required.";
    if ("vehicleType_" in input)
      temp.vehicleType_ = input.vehicleType_ ? "" : "This field is required.";
    if ("vehicleColor_" in input)
      temp.vehicleColor_ = input.vehicleColor_ ? "" : "This field is required.";

    setErrors({ ...temp });
    if (input === values) return Object.values(temp).every((x) => x === "");
  };

  const handleInputChange = (e, switchName = null) => {
    const { name, value } = e.target;
    values[name] = value;
    if (switchName) values[switchName] = true;

    setValues({ ...values });
    validate({ [name]: value });
  };

  const [step, setStep] = useState(0);
  const [items, setItems] = useState([]);
  const nextStep = () => {
    // ARRAY: create selected services array;
    const x = Object.keys(values).filter((k) => k.startsWith("_") && values[k]);
    setItems(x);
    setStep(step + 1);
  };

  // Go back to prev step
  const prevStep = () => {
    setStep(step - 1);
  };

  const showPreview = async (e) => {
    if (e.target.files) {
      // Set selected images files to upload in values
      const name = e.target.name;
      const files = e.target.files;
      setValues((prev) => ({ ...values, [name]: files }));

      // Crate Image list from selected image files to upload
      const fileArray = Array.from(files).map((file) =>
        URL.createObjectURL(file)
      );

      setImgList({ ...imgList, [name]: fileArray });
    }
  };

  const renderImageList = (source) => {
    return (
      <ImageList variant="masonry" cols={4} gap={8}>
        {source.map((el) => (
          <ImageListItem key={el}>
            <img src={el} alt={el} />
          </ImageListItem>
        ))}
      </ImageList>
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validate()) {
      setIsSaving(true);

      const data = {};
      const quoteServices = {};
      const quoteItems = {};
      const quoteInfo = {};
      for (const [key, val] of Object.entries(values)) {
        if (key.startsWith("_") && val) {
          const k = key.slice(1);
          quoteServices[k] = val;
          continue;
        }

        if (key.endsWith("_") && val) {
          const a = key.slice(0, -1);
          const b = a.replace(/([A-Z])/g, " $1").trim();
          const k = b.replace(/^\w/, (c) => c.toUpperCase());
          quoteInfo[k] = val;
          continue;
        }

        if (/^[A-Z]/.test(key[0]) && val) {
          quoteItems[key] = typeof val === "boolean" ? "Yes" : val;
          continue;
        }

        if (/^[a-z]/.test(key[0])) {
          data[key] = val;
          continue;
        }
      }

      for (const [key] of Object.entries(data)) {
        if (key.endsWith("_") || key.startsWith("_")) {
          delete data[key];
        }
      }

      let record = { ...data };
      record.quoteServices = JSON.stringify(quoteServices);
      record.quoteItems = JSON.stringify(quoteItems);
      record.quoteInfo = JSON.stringify(quoteInfo);

      // Create formData
      const formData = new FormData();
      // Add quote values to formData
      for (const [key, val] of Object.entries(record)) {
        formData.append(key, val);
      }

      // Add stripingFiles values to formData
      if (values.stripingFile) {
        for (const val of Object.values(values.stripingFile)) {
          formData.append("stripingFile", val);
        }
      }

      // Add vehicleFiles values to formData
      if (values.designWrapFile) {
        for (const val of Object.values(values.designWrapFile)) {
          formData.append("designWrapFile", val);
        }
      }

      // Add vehicleFiles values to formData
      if (values.vehicleFile) {
        for (const val of Object.values(values.vehicleFile)) {
          formData.append("vehicleFile", val);
        }
      }

      // POST request using fetch
      const requestOptions = {
        method: "POST",
        body: formData,
      };

      try {
        const response = await fetch(`${apiRequest}/Quote`, requestOptions);
        const isJson = response.headers
          .get("content-type")
          ?.includes("application/json");
        const json = isJson && (await response.json());

        // check for error response
        if (!response.ok) {
          if (response.status === 409) {
            setEmailConflict(true);
            return;
          }

          if (response.status === 404 || response.status === 500) {
            setNotFound(true);
            return;
          }

          // get error message from body or default to response status
          const error = (json && json.message) || response.status;
          throw Error(error);
        }

        setEmailConflict(false);
        setNotFound(false);
        setResponse(record);
      } catch (error) {
        setFetchError(
          <Alert severity="error">
            {!error.message ? error : error.message}
          </Alert>
        );
      } finally {
        setIsSaving(false);
      }
    }
  };

  return (
    <>
      {response && (
        <Navigate to="/thank-you" state={{ from: location }} replace />
      )}
      <PageHeader
        title="Automotive Services"
        subTitle="Select one or more services to receive a tailored quote within 24h"
        icon="wi-automotive"
      />
      <Container sx={{ pb: 5 }}>
        <form onSubmit={handleSubmit}>
          <Slide
            direction="right"
            in={step === 0 ? true : false}
            style={{ display: step === 0 ? "flex" : "none" }}
          >
            <Grid container>
              <Grid item xs={12} md={9}>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={6}>
                    <ServiceCard
                      services={[
                        "CarwrapOption",
                        "CarwrapColor",
                        "CarwrapColorImg",
                        "CarwrapParts",
                      ]}
                      values={values}
                      setValues={setValues}
                      switchName="_Carwrap"
                      icon="wi-carwrap"
                      title="Carwrap"
                      paragraph="Select this service if you want to wrap your car in different color. It can be full body or e.g. just the roof"
                    >
                      <RadioGroupControl
                        aria-label="Carwrap Option"
                        name="CarwrapOption"
                        onChange={(e) => handleInputChange(e, "_Carwrap")}
                        options={[
                          {
                            label: "Full Body Color Change",
                            value: "Full Body Color Change",
                          },
                          {
                            label: "Partial Color Change",
                            value: "Partial Color Change",
                          },
                        ]}
                      />
                      {values.CarwrapOption && (
                        <>
                          <ButtonControl
                            startIcon={<PaletteIcon />}
                            text="Select Color"
                            variant="outlined"
                            onClick={(e) => {
                              setPopupDialog(true);
                              setColorAttr(["CarwrapColor", "CarwrapColorImg"]);
                            }}
                            fullWidth
                          />
                          <InputControl
                            label="Color"
                            name="CarwrapColor"
                            value={
                              values.CarwrapColor ? values.CarwrapColor : ""
                            }
                            onChange={(e) => handleInputChange(e, "_Carwrap")}
                          />
                        </>
                      )}

                      {values.CarwrapOption === "Partial Color Change" && (
                        <InputControl
                          aria-label="Carwrap"
                          label="Which Part(s)"
                          name="CarwrapParts"
                          onChange={(e) => handleInputChange(e, "_Carwrap")}
                          multiline
                          rows={3}
                        />
                      )}
                    </ServiceCard>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <ServiceCard
                      services={[
                        "PaintProtectionOption",
                        "PaintProtectionMaterial",
                        "PaintProtectionParts",
                      ]}
                      values={values}
                      setValues={setValues}
                      switchName="_PaintProtection"
                      icon="wi-paint-protection"
                      title="Paint Protection"
                      paragraph="Select this service if you want to cover your vehicle with a transparent protection film. It can be matt or glossy."
                    >
                      <RadioGroupControl
                        aria-label="paint-protection"
                        name="PaintProtectionOption"
                        onChange={(e) => {
                          handleInputChange(e, "_PaintProtection");
                          if (!values.PaintProtectionMaterial)
                            setValues({
                              ...values,
                              PaintProtectionMaterial: "Glossy",
                            });
                        }}
                        options={[
                          {
                            label: "Basic",
                            value: "Basic",
                          },
                          {
                            label: "Bikini",
                            value: "Bikini",
                          },
                          {
                            label: "Full Front",
                            value: "Full Front",
                          },
                          {
                            label: "Full Body",
                            value: "Full Body",
                          },
                          {
                            label: "Other",
                            value: "Other",
                          },
                        ]}
                      />
                      {values.PaintProtectionOption === "Other" && (
                        <InputControl
                          label="Which Part(s)"
                          name="PaintProtectionParts"
                          onChange={(e) =>
                            handleInputChange(e, "_PaintProtection")
                          }
                          multiline
                          rows={3}
                        />
                      )}

                      {values.PaintProtectionOption && (
                        <>
                          <Divider />
                          <RadioGroupControl
                            aria-label="paint-protection-material"
                            label="Material:"
                            name="PaintProtectionMaterial"
                            value={values.PaintProtectionMaterial}
                            onChange={(e) =>
                              handleInputChange(e, "_PaintProtection")
                            }
                            options={[
                              {
                                label: "Glossy",
                                value: "Glossy",
                              },
                              {
                                label: "Matte",
                                value: "Matte",
                              },
                            ]}
                          />
                        </>
                      )}
                    </ServiceCard>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <ServiceCard
                      services={[
                        "StripingColor",
                        "StripingColorImg",
                        "stripingFile",
                      ]}
                      values={values}
                      setValues={setValues}
                      switchName="_Striping"
                      icon="wi-striping"
                      title="Striping"
                      paragraph="Select this service if you want to add a striping or graphic to your verhicle. It can be your design or we can draw it for you."
                    >
                      <ButtonControl
                        startIcon={<PaletteIcon />}
                        text="Select Color"
                        variant="outlined"
                        onClick={(e) => {
                          setPopupDialog(true);
                          setColorAttr(["StripingColor", "StripingColorImg"]);
                        }}
                        fullWidth
                      />
                      <InputControl
                        label="Color"
                        name="StripingColor"
                        value={values.StripingColor ? values.StripingColor : ""}
                        onChange={(e) => handleInputChange(e, "_Striping")}
                      />
                      <FormControl margin="dense" fullWidth>
                        <label htmlFor="striping-file">
                          <input
                            id="striping-file"
                            name="stripingFile"
                            type="file"
                            accept="image/*"
                            multiple
                            onChange={showPreview}
                            style={{ display: "none" }}
                          />
                          <ButtonControl
                            startIcon={<CloudUploadIcon />}
                            variant="outlined"
                            text="Upload Design / Example"
                            component="span"
                            fullWidth
                          />
                        </label>
                        {/* Render ImaList */}
                        {values.stripingFile &&
                          renderImageList(imgList.stripingFile)}
                      </FormControl>
                    </ServiceCard>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <ServiceCard
                      services={["DesignWrapOption", "designWrapFile"]}
                      values={values}
                      setValues={setValues}
                      switchName="_DesignWrap"
                      icon="wi-design-wrap"
                      title="Design Wrap"
                      paragraph="Select this service if you want to wrap your vehicle with a totally customized design. It can be your design or we can design it for you."
                    >
                      <CheckboxControl
                        label="Design Request"
                        name="DesignWrapOption"
                        onChange={(e) => handleInputChange(e, "_DesignWrap")}
                      />
                      <FormControl margin="dense" fullWidth>
                        <label htmlFor="designWrap-file">
                          <input
                            id="designWrap-file"
                            name="designWrapFile"
                            type="file"
                            accept="image/*"
                            multiple
                            onChange={showPreview}
                            style={{ display: "none" }}
                          />
                          <ButtonControl
                            startIcon={<CloudUploadIcon />}
                            variant="outlined"
                            text="Or Upload Your Design"
                            component="span"
                            fullWidth
                          />
                        </label>
                        {/* Render ImaList */}
                        {values.designWrapFile &&
                          renderImageList(imgList.designWrapFile)}
                      </FormControl>
                    </ServiceCard>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <ServiceCard
                      services={[
                        "AccentColor",
                        "AccentColorImg",
                        "AccentParts",
                      ]}
                      values={values}
                      setValues={setValues}
                      switchName="_AccentWrap"
                      icon="wi-accent-wrap"
                      title="Accent Wrap"
                      paragraph="Select this service if you want to wrap an accent on your vehicle like a mirror, spoiler, antenna, or whatever."
                    >
                      <ButtonControl
                        startIcon={<PaletteIcon />}
                        variant="outlined"
                        text="Select Color"
                        onClick={(e) => {
                          setPopupDialog(true);
                          setColorAttr(["AccentColor", "AccentColorImg"]);
                        }}
                        fullWidth
                      />
                      <InputControl
                        label="Color"
                        name="AccentColor"
                        value={values.AccentColor ? values.AccentColor : ""}
                        onChange={(e) => handleInputChange(e, "_AccentWrap")}
                      />
                      <InputControl
                        label="Which Part(s)"
                        name="AccentParts"
                        onChange={(e) => handleInputChange(e, "_AccentWrap")}
                        multiline
                        rows={3}
                      />
                    </ServiceCard>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <ServiceCard
                      services={["WindowTintOption", "WindowTintColor"]}
                      values={values}
                      setValues={setValues}
                      switchName="_WindowTint"
                      icon="wi-window-tint"
                      title="Window Tint"
                      paragraph="Select this service if you want to tint the windows of your car."
                    >
                      <RadioGroupControl
                        aria-label="window-tint"
                        name="WindowTintOption"
                        onChange={(e) => {
                          handleInputChange(e, "_WindowTint");
                          if (!values.WindowTintColor)
                            setValues({ ...values, WindowTintColor: "Light" });
                        }}
                        options={[
                          {
                            label: "All",
                            value: "All",
                          },
                          {
                            label: "All Except Windshield",
                            value: "All Except Windshield",
                          },
                          {
                            label: "All Behind Front Door",
                            value: "All Behind Front Door",
                          },
                          {
                            label: "Only Back Window",
                            value: "Only Back Window",
                          },
                          {
                            label: "Other",
                            value: "Other",
                          },
                        ]}
                      />
                      {values.WindowTintOption === "Other" && (
                        <InputControl
                          label="Which Part(s)"
                          name="WindowTintParts"
                          onChange={(e) => handleInputChange(e, "_WindowTint")}
                          multiline
                          rows={3}
                        />
                      )}
                      {values.WindowTintOption && (
                        <>
                          <Divider />
                          <RadioGroupControl
                            aria-label="window-tint-color"
                            label="Tint:"
                            name="WindowTintColor"
                            value={values.WindowTintColor}
                            onChange={(e) =>
                              handleInputChange(e, "_WindowTint")
                            }
                            options={[
                              {
                                label: "Light",
                                value: "Light",
                              },
                              {
                                label: "Medium Dark",
                                value: "Medium Dark",
                              },
                              {
                                label: "Limo Black",
                                value: "Limo Black",
                              },
                            ]}
                          />
                        </>
                      )}
                    </ServiceCard>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <ServiceCard
                      services={["WindshieldProtectionOption"]}
                      values={values}
                      setValues={setValues}
                      switchName="_WindshieldProtection"
                      icon="wi-windshield-protection"
                      title="Windshield Protection"
                      paragraph="Select this service if you want to protect your windshield (from the outside)."
                    >
                      <CheckboxControl
                        label="Windshield Protection"
                        name="WindshieldProtectionOption"
                        onChange={(e) =>
                          handleInputChange(e, "_WindshieldProtection")
                        }
                      />
                    </ServiceCard>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <ServiceCard
                      services={["CeramicCoatingOption"]}
                      values={values}
                      setValues={setValues}
                      switchName="_CeramicCoating"
                      icon="wi-ceramic-coating"
                      title="Ceramic Coating"
                      paragraph="Select this service if you want to protect your wrap or paint with a ceramic coating."
                    >
                      <CheckboxControl
                        label="Ceramic Coating"
                        name="CeramicCoatingOption"
                        onChange={(e) =>
                          handleInputChange(e, "_CeramicCoating")
                        }
                      />
                    </ServiceCard>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <ServiceCard
                      services={["SmokedLightsOption"]}
                      values={values}
                      setValues={setValues}
                      switchName="_SmokedLights"
                      icon="wi-smoked-lights"
                      title="Smoked Lights"
                      paragraph="Select this service if you want to darken your front or back-lights."
                    >
                      <RadioGroupControl
                        aria-label="smoked-lights"
                        name="SmokedLightsOption"
                        onChange={(e) => handleInputChange(e, "_SmokedLights")}
                        options={[
                          {
                            label: "Front Lights",
                            value: "Front Lights",
                          },
                          {
                            label: "Tail Lights",
                            value: "Tail Lights",
                          },
                          {
                            label: "Front and Tail Lights",
                            value: "Front and Tail Lights",
                          },
                        ]}
                      />
                    </ServiceCard>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <ServiceCard
                      services={["WheelsOption", "WheelsColor"]}
                      values={values}
                      setValues={setValues}
                      switchName="_Wheels"
                      icon="wi-wheels"
                      title="Wheels"
                      paragraph="Select this service if you want to powdercoat or wrap your wheels."
                    >
                      <RadioGroupControl
                        aria-label="wheels"
                        name="WheelsOption"
                        onChange={(e) => handleInputChange(e, "_Wheels")}
                        options={[
                          {
                            label: "Powder Coating",
                            value: "Powder Coating",
                          },
                          {
                            label: "Wrapping",
                            value: "Wrapping",
                          },
                        ]}
                      />
                      <InputControl
                        label="Desired Wheel Color"
                        name="WheelsColor"
                        onChange={(e) => handleInputChange(e, "_Wheels")}
                      />
                    </ServiceCard>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <ServiceCard
                      services={[
                        "ChromeDeleteColor",
                        "ChromeDeleteColorImg",
                        "ChromeDeleteParts",
                      ]}
                      values={values}
                      setValues={setValues}
                      switchName="_ChromeDelete"
                      icon="wi-chrome-delete"
                      title="Chrome Delete"
                      paragraph="Select this service if you want to replace chrome parts into another color."
                    >
                      <ButtonControl
                        startIcon={<PaletteIcon />}
                        variant="outlined"
                        text="Select Color"
                        onClick={(e) => {
                          setPopupDialog(true);
                          setColorAttr([
                            "ChromeDeleteColor",
                            "ChromeDeleteColorImg",
                          ]);
                        }}
                        fullWidth
                      />
                      <InputControl
                        label="Color"
                        name="ChromeDeleteColor"
                        value={
                          values.ChromeDeleteColor
                            ? values.ChromeDeleteColor
                            : ""
                        }
                        onChange={(e) => handleInputChange(e, "_ChromeDelete")}
                      />
                      <InputControl
                        label="Which Part(s)"
                        name="ChromeDeleteParts"
                        onChange={(e) => handleInputChange(e, "_ChromeDelete")}
                        multiline
                        rows={3}
                      />
                    </ServiceCard>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <ServiceCard
                      services={["OtherDetails"]}
                      values={values}
                      setValues={setValues}
                      switchName="_Other"
                      icon="wi-other"
                      title="Other"
                      paragraph="Select this service for all other inquiries."
                    >
                      <InputControl
                        label="Please Specify"
                        name="OtherDetails"
                        multiline
                        rows={3}
                        onChange={(e) => handleInputChange(e, "_Other")}
                      />
                    </ServiceCard>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={3} textAlign="center">
                <Fab
                  component={Link}
                  to="/"
                  aria-label="go back"
                  sx={{ mb: 3 }}
                >
                  <ArrowBackIcon />
                </Fab>
                <Typography color="text.secondary">
                  Select services form left and click CONFIRM once done
                </Typography>
                <Divider variant="middle" sx={{ my: 2 }}>
                  <Avatar>
                    <Icon className="wi-automotive" />
                  </Avatar>
                </Divider>
                {Object.keys(values).some(
                  (k) => /^[A-Z]/.test(k[0]) && values[k]
                ) ? (
                  <ButtonControl onClick={nextStep} text="CONFIRM" />
                ) : (
                  <ButtonControl text="CONFIRM" disabled />
                )}
              </Grid>
            </Grid>
          </Slide>

          <Slide
            direction="left"
            in={step === 1 ? true : false}
            style={{ display: step === 1 ? "flex" : "none" }}
          >
            <Grid container>
              <Grid item xs={12} md={9}>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={6}>
                    <Card elevation={3} sx={{ height: "100%" }}>
                      <CardHeader
                        avatar={
                          <Avatar>
                            <InfoOutlinedIcon />
                          </Avatar>
                        }
                        title="Vehicle"
                        titleTypographyProps={{ fontSize: "inherit" }}
                        subheader="Info"
                      />
                      <Divider />
                      <CardContent>
                        <InputControl
                          label="Vehicle Brand"
                          name="vehicleBrand_"
                          value={values.vehicleBrand_}
                          onChange={handleInputChange}
                          error={errors.vehicleBrand_}
                          helperText="example: BMW"
                        />
                        <InputControl
                          label="Vehicle Type"
                          name="vehicleType_"
                          value={values.vehicleType_}
                          onChange={handleInputChange}
                          error={errors.vehicleType_}
                          helperText="example: M3"
                        />
                        <InputControl
                          label="Orignal Color Of Your Vehicle"
                          name="vehicleColor_"
                          value={values.vehicleColor_}
                          onChange={handleInputChange}
                          error={errors.vehicleColor_}
                          helperText="example: Black"
                        />
                        <SelectControl
                          label="Vehicle Age"
                          name="vehicleAge_"
                          value={values.vehicleAge_}
                          onChange={handleInputChange}
                          options={[
                            { value: "New", label: "New" },
                            {
                              value: "Less than 3 years",
                              label: "Less than 3 years",
                            },
                            {
                              value: "More than 3 years",
                              label: "More than 3 years",
                            },
                          ]}
                          error={errors.vehicleAge_}
                        />
                        <FormControl margin="dense" fullWidth>
                          <label htmlFor="vehicle-file">
                            <input
                              id="vehicle-file"
                              name="vehicleFile"
                              type="file"
                              accept="image/*"
                              multiple
                              onChange={showPreview}
                              style={{ display: "none" }}
                            />
                            <ButtonControl
                              startIcon={<CloudUploadIcon />}
                              variant="outlined"
                              text="Upload Vehicle Pictures"
                              component="span"
                              fullWidth
                            />
                          </label>
                          {/* Render ImaList */}
                          {values.vehicleFile &&
                            renderImageList(imgList.vehicleFile)}
                        </FormControl>
                        <InputControl
                          label="Any Other Necessary Info"
                          name="necessaryInfo_"
                          value={values.necessaryInfo_}
                          onChange={handleInputChange}
                          error={errors.necessaryInfo_}
                          multiline
                          rows={3}
                        />
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <PersonalInfoForm
                      values={values}
                      handleInputChange={handleInputChange}
                      errors={errors}
                      isEmailConflict={isEmailConflict}
                      setEmailConflict={setEmailConflict}
                      isNotFound={isNotFound}
                      setNotFound={setNotFound}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={3} align="center">
                <Fab onClick={prevStep} aria-label="go back" sx={{ mb: 3 }}>
                  <ArrowBackIcon />
                </Fab>
                <Typography color="text.secondary">
                  Selected services
                </Typography>
                <List>
                  {items &&
                    items.map((item) => (
                      <ListItem key={item}>
                        <ListItemIcon>
                          <CheckIcon color="success" />
                        </ListItemIcon>
                        <ListItemText
                          primary={item
                            .slice(1)
                            .replace(/([A-Z])/g, " $1")
                            .trim()}
                        />
                      </ListItem>
                    ))}
                </List>
                <Divider variant="middle" sx={{ my: 2 }}>
                  {fetchError ? (
                    fetchError
                  ) : (
                    <Avatar>
                      <Icon className="wi-automotive" />
                    </Avatar>
                  )}
                </Divider>
                {!isSaving ? (
                  <ButtonControl
                    type="submit"
                    text="Submit"
                    isSaving={isSaving}
                  />
                ) : (
                  <ButtonControl
                    text="...Processing"
                    isSaving={isSaving}
                    disabled
                  />
                )}
              </Grid>
            </Grid>
          </Slide>
        </form>
      </Container>
      {/* PopupDialog */}
      <PopupDialog
        title="Select Color"
        popupDialog={popupDialog}
        setPopupDialog={setPopupDialog}
      >
        <ColorsList
          colorAttr={colorAttr}
          values={values}
          setValues={setValues}
          setPopupDialog={setPopupDialog}
        />
      </PopupDialog>
    </>
  );
}
