import { useState } from "react";
// import makeStyles from "@mui/styles/makeStyles";

export function useForm(objRecord, validateOnChange = false, validate) {
  const [values, setValues] = useState(objRecord);
  const [errors, setErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
    if (validateOnChange) validate({ [name]: value });
  };

  const resetForm = () => {
    setValues(objRecord);
    setErrors({});
  };

  return {
    values,
    setValues,
    handleInputChange,
    errors,
    setErrors,
    resetForm,
  };
}


export function Form(props) {
  // const classes = useStyles();

  return (
    <form onSubmit={props.onSubmit}>
      {props.children}
    </form>
  );
}
