import CancelIcon from "@mui/icons-material/Cancel";
import {
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import React, { memo } from "react";

function PopupDialog(props) {
  const { title, popupDialog, setPopupDialog, children } = props;

  const handleClose = () => {
    setPopupDialog(false);
  };

  return (
    <Dialog open={popupDialog} onClose={handleClose} maxWidth="100%">
      <DialogTitle>
        <Grid container alignItems="center">
          <Grid item xs>
            <Typography variant="subtitle1" component="h2">
              {title}
            </Typography>
          </Grid>
          <Grid item xs="auto">
            <Chip
              label="Close"
              icon={<CancelIcon />}
              onClick={() => setPopupDialog(false)}
            />
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent dividers>{children}</DialogContent>
    </Dialog>
  );
}

export default memo(PopupDialog);
