import {
  Container,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  Paper,
  Zoom,
} from "@mui/material";
import { Navigate, useLocation } from "react-router-dom";
import ButtonControl from "../components/controls/ButtonControl";
import PageHeader from "../components/PageHeader";
import useAppContext from "../hooks/useAppContext";

export default function ThankYou() {
  const { response: quote, setResponse } = useAppContext();
  const location = useLocation();

  const quoteInfo = quote && JSON.parse(quote.quoteInfo);
  const quoteItems = quote && JSON.parse(quote.quoteItems);

  return (
    <>
      {!quote ? (
        <Navigate to="/" state={{ from: location }} replace />
      ) : (
        <>
          <PageHeader
            title="Thank You"
            subTitle="We will get back to you asap"
            icon="wi-icon-2wrap"
          />
          <Container>
            <Zoom in={true} style={{ transitionDelay: "100ms" }}>
              <Grid container spacing={1}>
                <Grid item xs={12} md={3}>
                  <ButtonControl
                    text="Finish"
                    color="success"
                    onClick={() => setResponse()}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={5}>
                  <Paper sx={{ p: 2, height: "100%" }}>
                    <List>
                      <ListItem>
                        <ListItemText
                          style={{ textTransform: "uppercase" }}
                          primary={quote.industry}
                          secondary="Industry"
                        />
                      </ListItem>
                      <Divider component="li" />
                      {quoteItems &&
                        Object.entries(quoteItems).map(
                          ([key, value]) =>
                            !value?.startsWith("https:") && (
                              <ListItem key={key} divider>
                                <ListItemText
                                  sx={{ textTransform: "uppercase" }}
                                  primary={typeof value === "boolean" ? "Yes" : value}
                                  secondary={key.replace(/([A-Z])/g, " $1").trim()}
                                />
                              </ListItem>
                            )
                        )}
                      {quoteInfo &&
                        Object.entries(quoteInfo).map(
                          ([key, value]) =>
                            value && (
                              <ListItem key={key}>
                                <ListItemText
                                  sx={{ textTransform: "uppercase" }}
                                  primary={typeof value === "boolean" ? "Yes" : value}
                                  secondary={key.replace(/([A-Z])/g, " $1").trim()}
                                />
                              </ListItem>
                            )
                        )}
                    </List>
                  </Paper>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Paper sx={{ p: 2, height: "100%" }}>
                    {quote && (
                      <List>
                        <ListItem>
                          <ListItemText
                            sx={{ textTransform: "uppercase" }}
                            primary={quote.email}
                            secondary="Email"
                          />
                        </ListItem>
                        <ListItem>
                          <ListItemText
                            sx={{ textTransform: "uppercase" }}
                            primary={quote.firstName}
                            secondary="First Name"
                          />
                        </ListItem>
                        <ListItem>
                          <ListItemText
                            sx={{ textTransform: "uppercase" }}
                            primary={quote.lastName}
                            secondary="Last Name"
                          />
                        </ListItem>
                        <ListItem>
                          <ListItemText
                            sx={{ textTransform: "uppercase" }}
                            primary={quote.phoneNumber}
                            secondary="Phone No."
                          />
                        </ListItem>
                        <ListItem>
                          <ListItemText
                            sx={{ textTransform: "uppercase" }}
                            primary={quote.address}
                            secondary="Address"
                          />
                        </ListItem>
                        <ListItem>
                          <ListItemText
                            sx={{ textTransform: "uppercase" }}
                            primary={quote.city}
                            secondary="City"
                          />
                        </ListItem>
                        <ListItem>
                          <ListItemText
                            sx={{ textTransform: "uppercase" }}
                            primary={quote.state}
                            secondary="State"
                          />
                        </ListItem>
                        <ListItem>
                          <ListItemText
                            sx={{ textTransform: "uppercase" }}
                            primary={quote.zipCode}
                            secondary="Zip Code"
                          />
                        </ListItem>
                        {quote.company && (
                          <ListItem>
                            <ListItemText
                              sx={{ textTransform: "uppercase" }}
                              primary={quote.company}
                              secondary="Company"
                            />
                          </ListItem>
                        )}
                        {quote.taxNumber && (
                          <ListItem>
                            <ListItemText
                              sx={{ textTransform: "uppercase" }}
                              primary={quote.taxNumber}
                              secondary="Tax No."
                            />
                          </ListItem>
                        )}
                      </List>
                    )}
                  </Paper>
                </Grid>
              </Grid>
            </Zoom>
          </Container>
        </>
      )}
    </>
  );
}
