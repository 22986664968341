import SearchIcon from "@mui/icons-material/Search";
import {
  Alert,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  CircularProgress,
  Divider,
  Grid,
  InputAdornment,
  Stack,
  Typography,
} from "@mui/material";
import React, { memo, useEffect, useState } from "react";
import useAppContext from "../hooks/useAppContext";
import InputControl from "./controls/InputControl";

function ColorsList(props) {
  const { colorAttr, values, setValues, setPopupDialog } = props;
  const { apiColors } = useAppContext();
  const [records, setRecords] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [fetchError, setFetchError] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(-1);

  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });

  const handleSearch = (e) => {
    let target = e.target;
    setFilterFn({
      fn: (items) => {
        if (target.value === "") {
          return items;
        } else {
          return items.filter((item) =>
            ["name", "colorFinish", "colorVinyl"].some(
              (newitem) =>
                item[newitem]
                  .toString()
                  .toLowerCase()
                  .indexOf(target.value.toLowerCase()) > -1
            )
          );
        }
      },
    });
  };

  const handleOnClikc = (e) => {
    const ele = e.target.closest("[data-name]");
    const val = ele.dataset.val;
    const src = ele.dataset.src;
    setValues({
      ...values,
      [colorAttr[0]]: val,
      [colorAttr[1]]: src,
    });
    setPopupDialog(false);
    console.log(values);
  };

  useEffect(() => {
    const abortCtrl = new AbortController();

    const requestOptions = {
      headers: { "Content-Type": "application/json" },
    };

    // GET request using fetch with error handling
    const fetchRequest = async () => {
      try {
        const response = await fetch(apiColors, requestOptions, {
          signal: abortCtrl.signal,
        });
        const isJson = response.headers
          .get("content-type")
          ?.includes("application/json");
        const json = isJson && (await response.json());

        // check for error response
        if (!response.ok) {
          // get error message from body or default to response statusText
          const error =
            (json && json.message) + ` : ${response.status}` || response.status;
          throw Error(error);
        }

        setRecords(json);
        setFetchError(null);
      } catch (error) {
        setFetchError(
          <Alert severity="error">
            {!error.message ? error : error.message}
          </Alert>
        );
      } finally {
        setIsLoading(false);
      }
    };
    (async () => await fetchRequest())();

    return () => {
      abortCtrl.abort();
    };
  }, [apiColors]);

  return (
    <>
      {fetchError || (isLoading && <CircularProgress color="inherit" />)}
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <form noValidate autoComplete="off">
            <InputControl
              label="Search Color"
              name="name"
              type="search"
              placeholder="Search Color By Name, Brand Or Vinyl"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              onChange={handleSearch}
              margin="none"
            />
          </form>
        </Grid>
        {records &&
          filterFn.fn(records).map((it, i) => (
            <Grid
              item
              xs="auto"
              key={i}
              selected={selectedIndex === i}
              onClick={() => setSelectedIndex(i)}
            >
              <Card sx={{ width: 168 }}>
                <CardActionArea
                  data-name="Color"
                  data-val={`${it.name} : ${it.colorFinish} : ${it.colorVinyl}`}
                  data-src={it.imageName}
                  onClick={handleOnClikc}
                >
                  <CardMedia
                    component="img"
                    height="168"
                    image={
                      it.imageName ? it.imageName : "img/2wrap-icon-black.png"
                    }
                    alt={it.imageName}
                    loading="lazy"
                  />
                  <CardContent>
                    <Typography variant="subtitle2" component="h2" noWrap>
                      {it.name}
                    </Typography>
                    <Divider />
                    <Stack
                      direction="row"
                      alignItems="center"
                      spacing={0.5}
                      justifyContent="space-between"
                      sx={{ p: 0.5 }}
                    >
                      <Typography variant="body2" color="text.secondary">
                        {it.colorFinish}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {it.colorVinyl}
                      </Typography>
                    </Stack>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          ))}
      </Grid>
    </>
  );
}

export default memo(ColorsList);
