import { Grid, Icon, Typography } from "@mui/material";
import { memo } from "react";

function Aeronautics() {
  return (
    <Grid container spacing={1}>
      <Grid item xs={6} md={12}>
        <Icon className="wi-aeronautics-exterior" fontSize="large" />
        <Typography
          variant="overline"
          display="block"
          px={1}
          noWrap
          gutterBottom
        >
          Aeronautics Exterior
        </Typography>
      </Grid>
      <Grid item xs={6} md={12}>
        <Icon className="wi-aeronautics-interior" fontSize="large" />
        <Typography
          variant="overline"
          display="block"
          px={1}
          noWrap
          gutterBottom
        >
          Aeronautics Interior
        </Typography>
      </Grid>
    </Grid>
  );
}

export default memo(Aeronautics);
