import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import LockIcon from "@mui/icons-material/Lock";
import {
  Alert,
  Avatar,
  Container,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ButtonControl from "../components/controls/ButtonControl";
import InputControl from "../components/controls/InputControl";
import PageHeader from "../components/PageHeader";
import { useForm } from "../components/useForm";
import useAppContext from "../hooks/useAppContext";

const objRecord = {
  email: "",
  pin: "",
  password: "",
  confirmPassword: "",
};

export default function CustomerLogin() {
  const { apiAuths, auth, setAuth } = useAppContext();

  const [isSaving, setIsSaving] = useState(false);
  const [fetchError, setFetchError] = useState(null);
  const [isEmail, setIsEmail] = useState(false);
  const [isEmailConfirmed, setIsEmailConfirmed] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/portal";

  useEffect(() => {
    navigate(auth ? from : "/login", { replace: true });
    return () => {};
    // eslint-disable-next-line
  }, [auth, navigate, from]);

  const validate = (input = values) => {
    let temp = { ...errors };

    if ("email" in input) {
      temp.email = /^[A-Z0-9._%+-]+@([A-Z0-9-]+\.)+[A-Z]{2,4}$/i.test(
        input.email
      )
        ? ""
        : "Email in not valid.";
      temp.email = input.email ? temp.email : "Email is required.";
    }

    if (isEmail && isEmailConfirmed) {
      if ("password" in input) {
        temp.password =
          /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$ %^&*-]).{8,}$/.test(
            input.password
          )
            ? ""
            : "Minimum 8 characters, at least one upper case letter, one lower case letter, one number and one special character";

        temp.password = input.password
          ? temp.password
          : "Password is required.";
      }
    }

    if (isEmail && !isEmailConfirmed) {
      if ("pin" in input) {
        temp.pin = input.pin ? "" : "PIN is required.";
      }
      if ("password" in input) {
        temp.password =
          /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$ %^&*-]).{8,}$/.test(
            input.password
          )
            ? ""
            : "Minimum 8 characters, at least one upper case letter, one lower case letter, one number and one special character";

        temp.password = input.password
          ? temp.password
          : "Password is required.";
      }
      if ("confirmPassword" in input) {
        temp.confirmPassword =
          input.confirmPassword === values.password
            ? ""
            : "Password did not match";

        temp.confirmPassword = input.confirmPassword
          ? temp.confirmPassword
          : "Confirm Password is required.";
      }
    }

    setErrors({ ...temp });
    if (input === values) return Object.values(temp).every((x) => x === "");
  };

  const { values, handleInputChange, errors, setErrors } = useForm(
    objRecord,
    true,
    validate
  );

  const handleEmail = async (e) => {
    e.preventDefault();

    if (validate()) {
      setIsSaving(true);
      setFetchError(null);
      const abortCtrl = new AbortController();

      // GET Request using fetch
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(values),
      };

      // Fetch request with error handling
      try {
        const response = await fetch(`${apiAuths}/LoginEmail`, requestOptions, {
          signal: abortCtrl.signal,
        });
        const isJson = response.headers
          .get("content-type")
          ?.includes("application/json");
        const json = isJson && (await response.json());
        // check for error response
        if (!response.ok) {
          if (response.status === 401) setIsEmail(true);
          // get error message from body or default to response statusText
          const error =
            (json && json.message) + ` : ${response.status}` || response.status;
          throw Error(error);
        }

        setIsEmail(true);
        setIsEmailConfirmed(json.emailConfirmed);
      } catch (error) {
        setFetchError(
          <Alert severity="error">
            {!error.message ? error : error.message}
          </Alert>
        );
      } finally {
        setIsSaving(false);
        abortCtrl.abort();
      }
    }
  };

  const handleLogin = async (e) => {
    e.preventDefault();

    if (validate()) {
      setIsSaving(true);
      setFetchError(null);
      localStorage.removeItem("2wrap-Token-Token");
      setAuth(null);
      const abortCtrl = new AbortController();

      // GET Request using fetch
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(values),
      };

      // Fetch request with error handling
      try {
        const response = await fetch(`${apiAuths}/LoginPass`, requestOptions, {
          signal: abortCtrl.signal,
        });
        const isJson = response.headers
          .get("content-type")
          ?.includes("application/json");
        const json = isJson && (await response.json());
        // check for error response
        if (!response.ok) {
          // get error message from body or default to response statusText
          const error =
            (json && json.message) + ` : ${response.status}` || response.status;
          throw Error(error);
        }

        localStorage.setItem("2wrap-Token", JSON.stringify(json));
        setAuth(json);
      } catch (error) {
        setFetchError(
          <Alert severity="error">
            {!error.message ? error : error.message}
          </Alert>
        );
      } finally {
        setIsSaving(false);
        abortCtrl.abort();
      }
    }
  };

  const handleForget = async () => {
    setIsSaving(true);
    setFetchError(null);
    localStorage.removeItem("2wrap-Token-Token");
    setAuth(null);
    const abortCtrl = new AbortController();

    // GET Request using fetch
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(values),
    };

    // Fetch request with error handling
    try {
      const response = await fetch(`${apiAuths}/LoginForget`, requestOptions, {
        signal: abortCtrl.signal,
      });
      const isJson = response.headers
        .get("content-type")
        ?.includes("application/json");
      const json = isJson && (await response.json());
      // check for error response
      if (!response.ok) {
        if (response.status === 401) {
          setIsEmail(true);
          setIsEmailConfirmed(false);
        }
        // get error message from body or default to response statusText
        const error =
          (json && json.message) + ` : ${response.status}` || response.status;
        throw Error(error);
      }

      setIsEmail(true);
      setIsEmailConfirmed(json.emailConfirmed);
    } catch (error) {
      setFetchError(
        <Alert severity="error">{!error.message ? error : error.message}</Alert>
      );
    } finally {
      setIsSaving(false);
      abortCtrl.abort();
    }
  };

  const handleReset = async (e) => {
    e.preventDefault();

    if (validate()) {
      setIsSaving(true);
      setFetchError(null);
      localStorage.removeItem("2wrap-Token-Token");
      setAuth(null);
      const abortCtrl = new AbortController();

      // GET Request using fetch
      const requestOptions = {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(values),
      };

      // Fetch request with error handling
      try {
        const response = await fetch(`${apiAuths}/LoginReset`, requestOptions, {
          signal: abortCtrl.signal,
        });
        const isJson = response.headers
          .get("content-type")
          ?.includes("application/json");
        const json = isJson && (await response.json());
        // check for error response
        if (!response.ok) {
          // get error message from body or default to response statusText
          const error =
            (json && json.message) + ` : ${response.status}` || response.status;
          throw Error(error);
        }

        localStorage.setItem("2wrap-Token", JSON.stringify(json));
        setAuth(json);
      } catch (error) {
        setFetchError(
          <Alert severity="error">
            {!error.message ? error : error.message}
          </Alert>
        );
      } finally {
        setIsSaving(false);
        abortCtrl.abort();
      }
    }
  };

  return (
    <>
      <PageHeader
        title="Customer Portal"
        subTitle=". . ."
        icon="wi-icon-2wrap"
        maxWidth="xl"
        align="center"
      />
      <Container maxWidth="sm" disableGutters sx={{ p: 3 }}>
        <Grid container justifyContent="center" alignItems="center">
          <Grid item xs textAlign="center">
            <Typography variant="h5" gutterBottom>
              ACCOUNT
            </Typography>
            <Divider sx={{ mb: 1 }}>
              <Avatar>
                <LockIcon />
              </Avatar>
            </Divider>
            {fetchError}
            {!isEmail && !isEmailConfirmed && (
              <form onSubmit={handleEmail}>
                <List dense>
                  <ListItem>
                    <InputControl
                      type="email"
                      label="Email"
                      name="email"
                      value={values.email}
                      onChange={handleInputChange}
                      error={errors.email}
                    />
                  </ListItem>
                </List>
                <ButtonControl
                  type="submit"
                  text={isSaving ? "...Processing " : "Submit"}
                  isSaving={isSaving}
                />
              </form>
            )}

            {isEmail && isEmailConfirmed && (
              <form onSubmit={handleLogin}>
                <List dense>
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar>
                        <AccountCircleIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={values.email} />
                  </ListItem>
                  <ListItem>
                    <input
                      type="email"
                      name="email"
                      value={values.email}
                      readOnly
                      hidden
                    />
                    <InputControl
                      label="Password"
                      name="password"
                      type="password"
                      value={values.password}
                      onChange={handleInputChange}
                      error={errors.password}
                    />
                  </ListItem>
                </List>
                <ButtonControl
                  text="Back"
                  variant="outlined"
                  startIcon={<ArrowBackIcon />}
                  onClick={() => {
                    setIsEmail(false);
                    setIsEmailConfirmed(false);
                    setErrors({});
                  }}
                  sx={{ mr: 1 }}
                />
                <ButtonControl
                  type="submit"
                  text={isSaving ? "...Processing " : "Submit"}
                  isSaving={isSaving}
                />
                <Divider sx={{ my: 2 }}>
                  <ButtonControl
                    text="Forget Password"
                    variant="text"
                    size="medium"
                    onClick={handleForget}
                  />
                </Divider>
              </form>
            )}

            {isEmail && !isEmailConfirmed && (
              <form onSubmit={handleReset}>
                <List dense>
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar>
                        <AccountCircleIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={values.email} />
                  </ListItem>
                  <ListItem>
                    <input
                      type="email"
                      name="email"
                      value={values.email}
                      readOnly
                      hidden
                    />
                    <InputControl
                      label="PIN"
                      name="pin"
                      type="password"
                      value={values.pin}
                      onChange={handleInputChange}
                      error={errors.pin}
                    />
                  </ListItem>
                  <ListItem>
                    <InputControl
                      label="New Password"
                      name="password"
                      type="password"
                      value={values.password}
                      onChange={handleInputChange}
                      error={errors.password}
                    />
                  </ListItem>
                  <ListItem>
                    <InputControl
                      label="Confirm Password"
                      name="confirmPassword"
                      type="password"
                      value={values.confirmPassword}
                      onChange={handleInputChange}
                      error={errors.confirmPassword}
                    />
                  </ListItem>
                </List>
                <ButtonControl
                  text="Back"
                  variant="outlined"
                  startIcon={<ArrowBackIcon />}
                  onClick={() => {
                    setIsEmail(false);
                    setIsEmailConfirmed(false);
                    setFetchError(null);
                    setErrors({});
                  }}
                  sx={{ mr: 1 }}
                />
                <ButtonControl
                  type="submit"
                  text={isSaving ? "...Processing " : "Submit"}
                  isSaving={isSaving}
                />
              </form>
            )}
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
