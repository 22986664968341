import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Container,
  Divider,
  Grid,
  Icon,
  Typography,
  Zoom,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Advertising from "../../components/Advertising";
import Aeronautics from "../../components/Aeronautics";
import Architectural from "../../components/Architectural";
import Automotive from "../../components/Automotive";
import Nautical from "../../components/Nautical";
import PageHeader from "../../components/PageHeader";

export default function Industries() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 960);
  useEffect(() => {
    window.addEventListener(
      "resize",
      () => {
        const ismobile = window.innerWidth <= 960;
        if (ismobile !== isMobile) setIsMobile(!isMobile);
      },
      false
    );
  }, [isMobile]);

  return (
    <>
      <PageHeader
        title="..."
        subTitle="TO RECEIVE A TAILORED QUOTE, PLEASE SELECT ONE OF THE FOLLOWING INDUSTRIES"
        icon="wi-icon-2wrap"
        align="center"
      />
      <Container sx={{ pb: 5 }}>
        <Grid container spacing={1} justifyContent="center" textAlign="center">
          <Grid item xs={12} md={2}>
            <Zoom in={true} style={{ transitionDelay: "100ms" }}>
              <Card elevation={3}>
                <CardActionArea
                  component={Link}
                  to="/automotive-services"
                  sx={{
                    "&:hover > div:first-of-type": {
                      bgcolor: "secondary.main",
                    },
                  }}
                >
                  <Box
                    component="div"
                    sx={{
                      p: 2,
                      color: "white",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      bgcolor: "black",
                      transition: "all 0.2s linear",
                    }}
                  >
                    <Icon className="wi-automotive" sx={{ fontSize: 44 }} />
                    <Typography component="h3" variant="subtitle1" noWrap>
                      AUTOMOTIVE
                    </Typography>
                  </Box>
                  <CardContent>
                    <Automotive />
                  </CardContent>
                  <Divider light />
                  <Typography
                    color="text.secondary"
                    sx={{
                      p: 3,
                      bgcolor: "whitesmoke",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    all about CUSTOMIZING your vehicle
                  </Typography>
                </CardActionArea>
              </Card>
            </Zoom>
          </Grid>
          <Grid item xs={12} md={2}>
            <Zoom in={true} style={{ transitionDelay: "200ms" }}>
              <Card elevation={3}>
                <CardActionArea
                  component={Link}
                  to="/advertising-services"
                  sx={{
                    "&:hover > div:first-of-type": {
                      bgcolor: "secondary.main",
                    },
                  }}
                >
                  <Box
                    component="div"
                    sx={{
                      p: 2,
                      color: "white",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      bgcolor: "black",
                      transition: "all 0.2s linear",
                    }}
                  >
                    <Icon className="wi-advertising" sx={{ fontSize: 45 }} />
                    <Typography component="h3" variant="subtitle1" noWrap>
                      ADVERTISING
                    </Typography>
                  </Box>
                  <CardContent>
                    <Advertising />
                  </CardContent>
                  <Divider light />
                  <Typography
                    color="text.secondary"
                    sx={{
                      p: 3,
                      bgcolor: "whitesmoke",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    all about PROMOTING your business
                  </Typography>
                </CardActionArea>
              </Card>
            </Zoom>
          </Grid>
          <Grid item xs={12} md={2}>
            <Zoom in={true} style={{ transitionDelay: "300ms" }}>
              <Card elevation={3}>
                <CardActionArea
                  component={Link}
                  to="/architectural-services"
                  sx={{
                    "&:hover > div:first-of-type": {
                      bgcolor: "secondary.main",
                    },
                  }}
                >
                  <Box
                    component="div"
                    sx={{
                      p: 2,
                      color: "white",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      bgcolor: "black",
                      transition: "all 0.2s linear",
                    }}
                  >
                    <Icon className="wi-architectural" sx={{ fontSize: 45 }} />
                    <Typography component="h3" variant="subtitle1" noWrap>
                      ARCHITECTURAL
                    </Typography>
                  </Box>
                  <CardContent>
                    <Architectural />
                  </CardContent>
                  <Divider light />
                  <Typography
                    color="text.secondary"
                    sx={{
                      p: 3,
                      bgcolor: "whitesmoke",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    all about EMBELLISHING your building
                  </Typography>
                </CardActionArea>
              </Card>
            </Zoom>
          </Grid>
          <Grid item xs={12} md={2}>
            <Zoom in={true} style={{ transitionDelay: "400ms" }}>
              <Card elevation={3}>
                <CardActionArea
                  component={Link}
                  to="/nautical-services"
                  sx={{
                    "&:hover > div:first-of-type": {
                      bgcolor: "secondary.main",
                    },
                  }}
                >
                  <Box
                    component="div"
                    sx={{
                      p: 2,
                      color: "white",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      bgcolor: "black",
                      transition: "all 0.2s linear",
                    }}
                  >
                    <Icon className="wi-nautical" sx={{ fontSize: 45 }} />
                    <Typography component="h3" variant="subtitle1" noWrap>
                      NAUTICAL
                    </Typography>
                  </Box>
                  <CardContent>
                    <Nautical />
                  </CardContent>
                  <Divider light />
                  <Typography
                    color="text.secondary"
                    sx={{
                      p: 3,
                      bgcolor: "whitesmoke",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    all about CUSTOMIZING your boat
                  </Typography>
                </CardActionArea>
              </Card>
            </Zoom>
          </Grid>
          <Grid item xs={12} md={2}>
            <Zoom in={true} style={{ transitionDelay: "500ms" }}>
              <Card elevation={3}>
                <CardActionArea
                  component={Link}
                  to="/aeronautical-services"
                  sx={{
                    "&:hover > div:first-of-type": {
                      bgcolor: "secondary.main",
                    },
                  }}
                >
                  <Box
                    component="div"
                    sx={{
                      p: 2,
                      color: "white",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      bgcolor: "black",
                      transition: "all 0.2s linear",
                    }}
                  >
                    <Icon className="wi-aeronautics" sx={{ fontSize: 45 }} />
                    <Typography component="h2" variant="subtitle1" noWrap>
                      AERONAUTICS
                    </Typography>
                  </Box>
                  <CardContent>
                    <Aeronautics />
                  </CardContent>
                  <Divider light />
                  <Typography
                    color="text.secondary"
                    sx={{
                      p: 3,
                      bgcolor: "whitesmoke",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    all about EMBELLISHING your aircraft
                  </Typography>
                </CardActionArea>
              </Card>
            </Zoom>
          </Grid>
          <Grid item xs={12} md={2}>
            <Zoom in={true} style={{ transitionDelay: "600ms" }}>
              <Card elevation={3}>
                <CardActionArea
                  component={Link}
                  to="/specialties-services"
                  sx={{
                    "&:hover > div:first-of-type": {
                      bgcolor: "secondary.main",
                    },
                  }}
                >
                  <Box
                    component="div"
                    sx={{
                      p: 2,
                      color: "white",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      bgcolor: "black",
                      transition: "all 0.2s linear",
                    }}
                  >
                    <Icon className="wi-specialties" sx={{ fontSize: 45 }} />
                    <Typography component="h2" variant="subtitle1" noWrap>
                      SPECIALTIES
                    </Typography>
                  </Box>
                  <Typography
                    color="text.secondary"
                    sx={{
                      p: 3,
                      bgcolor: "whitesmoke",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    Everything else, we can't categorize
                  </Typography>
                </CardActionArea>
              </Card>
            </Zoom>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
