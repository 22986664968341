import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { memo } from "react";

function SelectControl(props) {
  const {
    variant,
    label,
    name,
    value,
    onChange,
    none = false,
    options,
    error = null,
  } = props;
  return (
    <FormControl
      variant={variant || "outlined"}
      {...(error && { error: true })}
      margin="dense"
      fullWidth
    >
      <InputLabel>{label}</InputLabel>
      <Select label={label} name={name} value={value} onChange={onChange}>
        {none && <MenuItem value="none">None</MenuItem>}
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
}

export default memo(SelectControl);
