import { Grid, Icon, Typography } from "@mui/material";
import { memo } from "react";

function Advertising() {
  return (
    <Grid container spacing={1}>
      <Grid item xs={6} md={12}>
        <Icon className="wi-fleet-marketing" fontSize="large" />
        <Typography
          variant="overline"
          display="block"
          px={1}
          noWrap
          gutterBottom
        >
          Fleet Marketing
        </Typography>
      </Grid>
      <Grid item xs={6} md={12}>
        <Icon className="wi-window-graphics" fontSize="large" />
        <Typography
          variant="overline"
          display="block"
          px={1}
          noWrap
          gutterBottom
        >
          Window Graphics
        </Typography>
      </Grid>
      <Grid item xs={6} md={12}>
        <Icon className="wi-graphic-design" fontSize="large" />
        <Typography
          variant="overline"
          display="block"
          px={1}
          noWrap
          gutterBottom
        >
          Graphic Design
        </Typography>
      </Grid>
      <Grid item xs={6} md={12}>
        <Icon className="wi-print-service" fontSize="large" />
        <Typography
          variant="overline"
          display="block"
          px={1}
          noWrap
          gutterBottom
        >
          Print Service
        </Typography>
      </Grid>
      <Grid item xs={6} md={12}>
        <Icon className="wi-signs" fontSize="large" />
        <Typography
          variant="overline"
          display="block"
          px={1}
          noWrap
          gutterBottom
        >
          Signs
        </Typography>
      </Grid>
      <Grid item xs={6} md={12}>
        <Icon className="wi-flags-banners" fontSize="large" />
        <Typography
          variant="overline"
          display="block"
          px={1}
          noWrap
          gutterBottom
        >
          Flags &amp; Banners
        </Typography>
      </Grid>
      <Grid item xs={6} md={12}>
        <Icon className="wi-exhibit-stands" fontSize="large" />
        <Typography
          variant="overline"
          display="block"
          px={1}
          noWrap
          gutterBottom
        >
          Exhibit Stands
        </Typography>
      </Grid>
    </Grid>
  );
}

export default memo(Advertising);
